import React, { useState } from 'react';
import { 
  Search, 
  Plus, 
  Filter, 
  UserPlus,
  Mail,
  Phone,
  MapPin,
  Flag,
  Calendar,
  Clock,
  MoreVertical,
  Download,
  Trash2,
  Edit2,
  Star,
  History
} from 'lucide-react';
import './Index.scss';

const ClientList = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filterNationality, setFilterNationality] = useState('all');
  const [filterStatus, setFilterStatus] = useState('all');
  const [viewMode, setViewMode] = useState('grid'); // grid or table
  const [sortBy, setSortBy] = useState('recent'); // recent, name, tours
  const [selectedClient, setSelectedClient] = useState(null);

  // Sample data
  const clients = [
    {
      id: 1,
      name: 'John Smith',
      email: 'john.smith@example.com',
      phone: '+1 234-567-8900',
      nationality: 'USA',
      avatar: 'https://static-00.iconduck.com/assets.00/avatar-default-icon-2048x2048-h6w375ur.png',
      status: 'active',
      totalBookings: 5,
      lastBooking: '2024-02-15',
      nextTour: {
        name: 'Bromo Sunrise Tour',
        date: '2024-03-20'
      },
      preferences: {
        language: 'English',
        dietRestrictions: ['Vegetarian'],
        interests: ['Photography', 'Culture', 'Nature']
      },
      bookingHistory: [
        {
          id: 'BK001',
          tour: 'Ijen Crater Tour',
          date: '2023-12-10',
          amount: 2500000,
          status: 'completed'
        },
        {
          id: 'BK002',
          tour: 'Malang City Tour',
          date: '2024-01-15',
          amount: 1500000,
          status: 'completed'
        }
      ]
    },
    {
      id: 2,
      name: 'Yuki Tanaka',
      email: 'yuki.t@example.jp',
      phone: '+81 90-1234-5678',
      nationality: 'Japan',
      avatar: 'https://static-00.iconduck.com/assets.00/avatar-default-icon-2048x2048-h6w375ur.png',
      status: 'active',
      totalBookings: 3,
      lastBooking: '2024-02-20',
      nextTour: {
        name: 'Ijen Blue Fire Tour',
        date: '2024-03-15'
      },
      preferences: {
        language: 'Japanese',
        dietRestrictions: ['Halal'],
        interests: ['Volcano', 'Hot Springs']
      },
      bookingHistory: [
        {
          id: 'BK003',
          tour: 'Mount Bromo Tour',
          date: '2024-02-20',
          amount: 3000000,
          status: 'upcoming'
        }
      ]
    },
    {
      id: 3,
      name: 'Sophie Martin',
      email: 'sophie.m@example.fr',
      phone: '+33 6 12 34 56 78',
      nationality: 'France',
      avatar: 'https://static-00.iconduck.com/assets.00/avatar-default-icon-2048x2048-h6w375ur.png',
      status: 'inactive',
      totalBookings: 1,
      lastBooking: '2023-11-10',
      nextTour: null,
      preferences: {
        language: 'English',
        dietRestrictions: [],
        interests: ['History', 'Local Food']
      },
      bookingHistory: [
        {
          id: 'BK004',
          tour: 'East Java Heritage Tour',
          date: '2023-11-10',
          amount: 4000000,
          status: 'completed'
        }
      ]
    }
  ];

  // Nationality options for filter
  const nationalities = [
    { value: 'all', label: 'All Nationalities' },
    { value: 'USA', label: 'United States' },
    { value: 'Japan', label: 'Japan' },
    { value: 'France', label: 'France' },
    { value: 'Australia', label: 'Australia' }
  ];

  // Status options for filter
  const statusOptions = [
    { value: 'all', label: 'All Status' },
    { value: 'active', label: 'Active' },
    { value: 'inactive', label: 'Inactive' }
  ];

  // Sort options
  const sortOptions = [
    { value: 'recent', label: 'Most Recent' },
    { value: 'name', label: 'Name' },
    { value: 'tours', label: 'Total Tours' }
  ];

  const getFilteredClients = () => {
    return clients.filter(client => {
      const matchesSearch = 
        client.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        client.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        client.phone.toLowerCase().includes(searchTerm.toLowerCase());
      
      const matchesNationality = filterNationality === 'all' || client.nationality === filterNationality;
      const matchesStatus = filterStatus === 'all' || client.status === filterStatus;

      return matchesSearch && matchesNationality && matchesStatus;
    }).sort((a, b) => {
      switch (sortBy) {
        case 'name':
          return a.name.localeCompare(b.name);
        case 'tours':
          return b.totalBookings - a.totalBookings;
        case 'recent':
        default:
          return new Date(b.lastBooking) - new Date(a.lastBooking);
      }
    });
  };

  const handleExportData = () => {
    // Implement export functionality
    console.log('Exporting client data...');
  };

  return (
    <div className="client-list">
      {/* Header */}
      <div className="list-header">
        <div className="header-left">
          <h1>Clients</h1>
          <p>Manage your client information and booking history</p>
        </div>
        <div className="header-actions">
          <button className="export-btn" onClick={handleExportData}>
            <Download size={20} />
            Export
          </button>
          <button className="add-btn">
            <UserPlus size={20} />
            Add Client
          </button>
        </div>
      </div>

      {/* Filters */}
      <div className="list-filters">
        <div className="filters-left">
          <div className="search-bar">
            <Search size={20} />
            <input
              type="text"
              placeholder="Search clients..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className="filter-group">
            <select 
              value={filterNationality}
              onChange={(e) => setFilterNationality(e.target.value)}
              className="filter-select"
            >
              {nationalities.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            <select
              value={filterStatus}
              onChange={(e) => setFilterStatus(e.target.value)}
              className="filter-select"
            >
              {statusOptions.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="filters-right">
          <select
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value)}
            className="sort-select"
          >
            {sortOptions.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          <div className="view-switcher">
            <button
              className={`view-btn ${viewMode === 'grid' ? 'active' : ''}`}
              onClick={() => setViewMode('grid')}
            >
              Grid
            </button>
            <button
              className={`view-btn ${viewMode === 'table' ? 'active' : ''}`}
              onClick={() => setViewMode('table')}
            >
              Table
            </button>
          </div>
        </div>
      </div>

      {/* Grid View */}
      {viewMode === 'grid' && (
        <div className="client-grid">
          {getFilteredClients().map(client => (
            <div key={client.id} className="client-card">
              <div className="card-header">
                <div className="client-info">
                  <img src={client.avatar} alt={client.name} className="client-avatar" />
                  <div>
                    <h3>{client.name}</h3>
                    <span className={`status-badge ${client.status}`}>
                      {client.status.charAt(0).toUpperCase() + client.status.slice(1)}
                    </span>
                  </div>
                </div>
                <button className="more-btn">
                  <MoreVertical size={16} />
                </button>
              </div>

              <div className="card-content">
                <div className="contact-info">
                  <div className="info-item">
                    <Mail size={16} />
                    <span>{client.email}</span>
                  </div>
                  <div className="info-item">
                    <Phone size={16} />
                    <span>{client.phone}</span>
                  </div>
                  <div className="info-item">
                    <Flag size={16} />
                    <span>{client.nationality}</span>
                  </div>
                </div>

                <div className="booking-info">
                  <div className="stat-item">
                    <span className="label">Total Tours</span>
                    <span className="value">{client.totalBookings}</span>
                  </div>
                  <div className="stat-item">
                    <span className="label">Last Booking</span>
                    <span className="value">
                      {new Date(client.lastBooking).toLocaleDateString()}
                    </span>
                  </div>
                </div>

                {client.nextTour && (
                  <div className="next-tour">
                    <h4>Upcoming Tour</h4>
                    <div className="tour-details">
                      <span className="tour-name">{client.nextTour.name}</span>
                      <span className="tour-date">
                        <Calendar size={14} />
                        {new Date(client.nextTour.date).toLocaleDateString()}
                      </span>
                    </div>
                  </div>
                )}

                <div className="preferences">
                  <h4>Preferences</h4>
                  <div className="preference-tags">
                    {client.preferences.interests.map((interest, index) => (
                      <span key={index} className="tag">{interest}</span>
                    ))}
                  </div>
                </div>
              </div>

              <div className="card-actions">
                <button className="action-btn">View Profile</button>
                <button className="action-btn primary">Book Tour</button>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Table View */}
      {viewMode === 'table' && (
        <div className="client-table">
          <table>
            <thead>
              <tr>
                <th>Client Name</th>
                <th>Nationality</th>
                <th>Status</th>
                <th>Total Tours</th>
                <th>Last Booking</th>
                <th>Next Tour</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {getFilteredClients().map(client => (
                <tr key={client.id}>
                  <td>
                    <div className="client-cell">
                      <img src={client.avatar} alt={client.name} />
                      <div>
                        <span className="name">{client.name}</span>
                        <span className="email">{client.email}</span>
                      </div>
                    </div>
                  </td>
                  <td>{client.nationality}</td>
                  <td>
                    <span className={`status-badge ${client.status}`}>
                      {client.status.charAt(0).toUpperCase() + client.status.slice(1)}
                    </span>
                  </td>
                  <td>{client.totalBookings}</td>
                  <td>{new Date(client.lastBooking).toLocaleDateString()}</td>
                  <td>
                    {client.nextTour ? (
                      <div className="next-tour-cell">
                        <span>{client.nextTour.name}</span>
                        <span className="date">
                          {new Date(client.nextTour.date).toLocaleDateString()}
                        </span>
                      </div>
                    ) : (
                      <span className="no-tour">No upcoming tours</span>
                    )}
                  </td>
                  <td>
                    <div className="action-buttons">
                      <button className="icon-btn">
                        <Edit2 size={16} />
                      </button>
                      <button className="icon-btn">
                        <History size={16} />
                      </button>
                      <button className="icon-btn delete">
                        <Trash2 size={16} />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ClientList;
