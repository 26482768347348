import React, { useState } from 'react';
import { Save, X, Upload } from 'lucide-react';
import './Create.scss';

const Create = () => {
  const [formData, setFormData] = useState({
    name: '',
    role: '',
    specialization: '',
    phone: '',
    email: '',
    location: '',
    languages: [],
    certifications: [],
    status: 'available'
  });

  const [errors, setErrors] = useState({});
  const [preview, setPreview] = useState(null);

  const roles = [
    'Tour Guide',
    'Senior Guide',
    'Local Guide',
    'Driver Guide',
    'Trainee Guide'
  ];

  const availableLanguages = [
    'English',
    'Indonesian',
    'Japanese',
    'Mandarin',
    'Korean',
    'Dutch',
    'French',
    'German'
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    // Clear error when user starts typing
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleLanguageChange = (language) => {
    setFormData(prev => {
      if (prev.languages.includes(language)) {
        return {
          ...prev,
          languages: prev.languages.filter(lang => lang !== language)
        };
      } else {
        return {
          ...prev,
          languages: [...prev.languages, language]
        };
      }
    });
  };

  const handleAddCertification = (e) => {
    if (e.key === 'Enter' && e.target.value.trim()) {
      e.preventDefault();
      setFormData(prev => ({
        ...prev,
        certifications: [...prev.certifications, e.target.value.trim()]
      }));
      e.target.value = '';
    }
  };

  const removeCertification = (index) => {
    setFormData(prev => ({
      ...prev,
      certifications: prev.certifications.filter((_, i) => i !== index)
    }));
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.name.trim()) newErrors.name = 'Name is required';
    if (!formData.role) newErrors.role = 'Role is required';
    if (!formData.phone.trim()) newErrors.phone = 'Phone is required';
    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Invalid email format';
    }
    if (!formData.location.trim()) newErrors.location = 'Location is required';
    if (formData.languages.length === 0) newErrors.languages = 'Select at least one language';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      console.log('Form submitted:', formData);
      // Handle form submission
    }
  };

  return (
    <div className="create-crew">
      <div className="form-header">
        <div>
          <h1>Add New Crew Member</h1>
          <p>Enter the crew member's information below</p>
        </div>
        <div className="header-buttons">
          <button className="cancel-btn">
            <X size={20} />
            Cancel
          </button>
          <button className="save-btn" onClick={handleSubmit}>
            <Save size={20} />
            Save
          </button>
        </div>
      </div>

      <form onSubmit={handleSubmit} className="crew-form">
        <div className="form-grid">
          {/* Left Column */}
          <div className="form-left">
            {/* Image Upload Section */}
            <div className="upload-section">
              <div className="avatar-upload">
                {preview ? (
                  <img src={preview} alt="Preview" className="avatar-preview" />
                ) : (
                  <div className="upload-placeholder">
                    <Upload size={24} />
                    <span>Upload Photo</span>
                  </div>
                )}
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageUpload}
                  className="file-input"
                />
              </div>
              <p className="upload-help">Upload a profile photo (Optional)</p>
            </div>

            {/* Basic Information */}
            <div className="form-section">
              <h2>Basic Information</h2>
              
              <div className="form-group">
                <label htmlFor="name">Full Name*</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  className={errors.name ? 'error' : ''}
                />
                {errors.name && <span className="error-message">{errors.name}</span>}
              </div>

              <div className="form-group">
                <label htmlFor="role">Role*</label>
                <select
                  id="role"
                  name="role"
                  value={formData.role}
                  onChange={handleInputChange}
                  className={errors.role ? 'error' : ''}
                >
                  <option value="">Select a role</option>
                  {roles.map(role => (
                    <option key={role} value={role}>{role}</option>
                  ))}
                </select>
                {errors.role && <span className="error-message">{errors.role}</span>}
              </div>

              <div className="form-group">
                <label htmlFor="specialization">Specialization</label>
                <input
                  type="text"
                  id="specialization"
                  name="specialization"
                  value={formData.specialization}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>

          {/* Right Column */}
          <div className="form-right">
            {/* Contact Information */}
            <div className="form-section">
              <h2>Contact Information</h2>

              <div className="form-group">
                <label htmlFor="phone">Phone Number*</label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  className={errors.phone ? 'error' : ''}
                />
                {errors.phone && <span className="error-message">{errors.phone}</span>}
              </div>

              <div className="form-group">
                <label htmlFor="email">Email Address*</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className={errors.email ? 'error' : ''}
                />
                {errors.email && <span className="error-message">{errors.email}</span>}
              </div>

              <div className="form-group">
                <label htmlFor="location">Location*</label>
                <input
                  type="text"
                  id="location"
                  name="location"
                  value={formData.location}
                  onChange={handleInputChange}
                  className={errors.location ? 'error' : ''}
                />
                {errors.location && <span className="error-message">{errors.location}</span>}
              </div>
            </div>

            {/* Languages */}
            <div className="form-section">
              <h2>Languages</h2>
              <div className="language-selection">
                {availableLanguages.map(language => (
                  <label key={language} className="language-checkbox">
                    <input
                      type="checkbox"
                      checked={formData.languages.includes(language)}
                      onChange={() => handleLanguageChange(language)}
                    />
                    <span>{language}</span>
                  </label>
                ))}
              </div>
              {errors.languages && <span className="error-message">{errors.languages}</span>}
            </div>

            {/* Certifications */}
            <div className="form-section">
              <h2>Certifications</h2>
              <div className="certification-input">
                <input
                  type="text"
                  placeholder="Type certification and press Enter"
                  onKeyPress={handleAddCertification}
                />
              </div>
              <div className="certification-tags">
                {formData.certifications.map((cert, index) => (
                  <span key={index} className="cert-tag">
                    {cert}
                    <button type="button" onClick={() => removeCertification(index)}>×</button>
                  </span>
                ))}
              </div>
            </div>

            {/* Status */}
            <div className="form-section">
              <h2>Status</h2>
              <div className="status-selection">
                <label className="status-radio">
                  <input
                    type="radio"
                    name="status"
                    value="available"
                    checked={formData.status === 'available'}
                    onChange={handleInputChange}
                  />
                  <span>Available</span>
                </label>
                <label className="status-radio">
                  <input
                    type="radio"
                    name="status"
                    value="onTour"
                    checked={formData.status === 'onTour'}
                    onChange={handleInputChange}
                  />
                  <span>On Tour</span>
                </label>
                <label className="status-radio">
                  <input
                    type="radio"
                    name="status"
                    value="onLeave"
                    checked={formData.status === 'onLeave'}
                    onChange={handleInputChange}
                  />
                  <span>On Leave</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Create;