import React from 'react';
import { BarChart, LineChart, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { ClipboardList, DollarSign, Wallet, TrendingUp } from 'lucide-react';

// Sample data for charts
const revenueData = [
  { name: 'Mon', revenue: 100000, expenses: 80000 },
  { name: 'Tue', revenue: 120000, expenses: 95000 },
  { name: 'Wed', revenue: 90000, expenses: 70000 },
  { name: 'Thu', revenue: 110000, expenses: 85000 },
  { name: 'Fri', revenue: 150000, expenses: 130000 },
  { name: 'Sat', revenue: 170000, expenses: 110000 },
  { name: 'Sun', revenue: 140000, expenses: 100000 },
];

const Dashboard = () => {
  const statsCards = [
    { icon: <ClipboardList size={24} />, title: 'Active Bookings', value: '45', color: 'blue' },
    { icon: <DollarSign size={24} />, title: "Today's Revenue", value: 'IDR 120,000,000', color: 'green' },
    { icon: <Wallet size={24} />, title: "Today's Expenses", value: 'IDR 80,000,000', color: 'red' },
    { icon: <TrendingUp size={24} />, title: "Today's Profit", value: 'IDR 40,000,000', color: 'purple' },
  ];

  return (
    <>
      {/* Stats Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-6">
        {statsCards.map((stat, index) => (
          <div key={index} className="bg-white rounded-lg shadow-md p-6">
            <div className={`flex items-center justify-center w-12 h-12 rounded-full bg-${stat.color}-100 text-${stat.color}-600 mb-4`}>
              {stat.icon}
            </div>
            <h3 className="text-gray-600 text-sm font-medium">{stat.title}</h3>
            <p className="text-gray-900 text-lg font-semibold mt-2">{stat.value}</p>
          </div>
        ))}
      </div>

      {/* Charts */}
      <div className="bg-white rounded-lg shadow-md p-6 mb-6">
        <h2 className="text-lg font-semibold mb-4">Revenue vs Expenses</h2>
        <div className="h-80">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={revenueData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="revenue" stroke="#2563eb" name="Revenue" />
              <Line type="monotone" dataKey="expenses" stroke="#dc2626" name="Expenses" />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* Recent Bookings Table */}
      <div className="bg-white rounded-lg shadow-md overflow-hidden">
        <div className="p-6 border-b border-gray-200">
          <h2 className="text-lg font-semibold">Recent Bookings</h2>
        </div>
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Booking ID</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Client Name</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Tour Package</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Start Date</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              <tr>
                <td className="px-6 py-4 whitespace-nowrap">BK-001</td>
                <td className="px-6 py-4 whitespace-nowrap">John Doe</td>
                <td className="px-6 py-4 whitespace-nowrap">3D2N Bromo-Ijen Tour</td>
                <td className="px-6 py-4 whitespace-nowrap">2024-10-20</td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                    Confirmed
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                  <button className="text-blue-600 hover:text-blue-900 mr-3">View</button>
                  <button className="text-red-600 hover:text-red-900">Cancel</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Dashboard;