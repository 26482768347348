import React, { useEffect, useState } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';

const Index = () => {
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        setLoading(true);
        const response = await axios.get('https://api-php.javavolcano-touroperator.com/');
        setBookings(response.data || []);
      } catch (error) {
        console.error('Error fetching booking data:', error);
        setBookings([]);
      } finally {
        setLoading(false);
      }
    };

    fetchBookings();
  }, []);

  const formatDateRange = (startDate, endDate) => {
    if (!startDate || !endDate) return '-';
    
    const start = dayjs(startDate);
    const end = dayjs(endDate);
    
    // If same month and year
    if (start.format('MMM YYYY') === end.format('MMM YYYY')) {
      return `${start.format('ddd DD')} - ${end.format('DD MMM YYYY')}`;
    }
    // If same year but different month
    else if (start.format('YYYY') === end.format('YYYY')) {
      return `${start.format('ddd DD MMM')} - ${end.format('DD MMM YYYY')}`;
    }
    // If different year
    else {
      return `${start.format('ddd DD MMM YYYY')} - ${end.format('DD MMM YYYY')}`;
    }
  };

  const formatSingleDate = (dateString) => {
    if (!dateString) return '-';
    return dayjs(dateString).format('ddd DD MMM YYYY');
  };

  const formatTime = (timeString) => {
    if (!timeString) return '-';
    return timeString;
  };
  
  if (loading) {
    return (
      <div className="p-6 flex justify-center items-center">
        <div className="text-gray-500">Loading bookings...</div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden">
      <div className="p-6 border-b border-gray-200">
        <h2 className="text-lg font-semibold">Recent Bookings</h2>
      </div>
      <div className="overflow-x-auto">
        <table className="w-full">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">No</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">BOOKING ID</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Guests & Package</th>
              <th width="10%" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Pick Up</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Itinerary</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Hotel</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Room Type</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">T-Shirt Size</th>
              <th width="10%" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Drop</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Car / Driver</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Note</th>
              {/* <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th> */}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {bookings.map((booking, index) => (
              <tr key={booking.id || index} className="hover:bg-gray-50">
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{index + 1}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{booking.invoice_number}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {formatDateRange(booking.start_date, booking.end_date)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    <div className="font-medium">{booking.client_name}</div>
                    <div className="text-gray-600">{booking.duration}D {booking.duration-1}N / {booking.no_of_pax} PAX</div>
                    {booking.booking_channel && (
                    <div className="mt-1">
                        {booking.booking_channel === 'TWT' && (
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded text-xs font-medium bg-yellow-100 text-yellow-800">
                            TWT
                        </span>
                        )}
                        {booking.booking_channel === 'JVTO' && (
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded text-xs font-medium bg-blue-600 text-white">
                            JVTO
                        </span>
                        )}
                        {booking.booking_channel === 'KLOOK' && (
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded text-xs font-medium bg-orange-500 text-white">
                            KLOOK
                        </span>
                        )}
                    </div>
                    )}
                    </td>
                <td className="px-6 py-4 text-sm text-gray-900">
                  <div>{booking.pickup_details}</div>
                </td>
                <td className="px-6 py-4 text-sm text-gray-900">
                    {booking.itineraries && booking.itineraries.map((itinerary, idx) => (
                        <div key={itinerary.id} className={`${idx !== 0 ? 'mt-2' : ''}`}>
                        <div className="flex items-center">
                            <div>
                                <span className="text-blue-600 font-bold mr-1">
                                Day {itinerary.day}
                                </span>
                                {itinerary.activity}
                            </div>
                        </div>
                        <div className="text-sm">
                        </div>
                        </div>
                    ))}
                    {!booking.itineraries?.length && '-'}
                </td>                
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {booking.accommodations && booking.accommodations.map((accommodation, idx) => (
                        <div key={accommodation.id} className={`${idx !== 0 ? 'mt-1' : ''}`}>
                        <span className="font-medium">{dayjs(accommodation.check_in_date).format('DD')}</span>
                        <span className="text-gray-500"> : </span>
                        <span>{accommodation.hotel_name}</span>
                        </div>
                    ))}
                    {!booking.accommodations?.length && '-'}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {booking.accommodations && booking.accommodations.map((accommodation, idx) => (
                        <div key={accommodation.id} className={`${idx !== 0 ? 'mt-1' : ''}`}>
                            <span>
                            {accommodation.room_type !== '-' && accommodation.quantity !== 0 
                                ? `${accommodation.room_type} x ${accommodation.quantity}` 
                                : '-'}
                            </span>
                        </div>
                    ))}
                    {!booking.accommodations?.length && '-'}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{booking.tshirt || '-'}</td>
                <td className="px-6 py-4 text-sm text-gray-900">
                  <div>{booking.drop_details}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{booking.vehicles[0].vehicle_name}</td>
                <td className="px-6 py-4 text-sm text-gray-900">{booking.remarks || '-'}</td>
              </tr>
            ))}
            {bookings.length === 0 && (
              <tr>
                <td colSpan={12} className="px-6 py-4 text-center text-sm text-gray-500">
                  No bookings found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Index;