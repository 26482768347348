import React, { useState } from 'react';
import { 
  Calendar, 
  Truck, 
  Users, 
  Building2, 
  AlertCircle, 
  Clock, 
  CheckCircle2, 
  XCircle,
  AlertTriangle,
  Search,
  Filter,
  Calendar as CalendarIcon
} from 'lucide-react';
import './Index.scss';

const ResourceDashboard = () => {
  const [dateRange, setDateRange] = useState('today');
  const [searchTerm, setSearchTerm] = useState('');

  // Sample data
  const stats = {
    vehicles: {
      total: 24,
      available: 15,
      onDuty: 7,
      maintenance: 2
    },
    guides: {
      total: 18,
      available: 12,
      onDuty: 5,
      onLeave: 1
    },
    hotels: {
      totalRooms: 150,
      availableRooms: 85,
      bookedRooms: 55,
      maintenance: 10
    }
  };

  const upcomingAssignments = [
    {
      id: 1,
      type: 'vehicle',
      name: 'Toyota Hiace',
      assignment: 'Bromo Sunrise Tour',
      driver: 'John Smith',
      startDate: '2024-03-01 04:00',
      endDate: '2024-03-03 14:00',
      status: 'confirmed'
    },
    {
      id: 2,
      type: 'guide',
      name: 'David Chen',
      assignment: 'Ijen Crater Tour',
      startDate: '2024-03-02 00:00',
      endDate: '2024-03-04 12:00',
      status: 'pending'
    },
    {
      id: 3,
      type: 'hotel',
      name: 'Bromo Valley Hotel',
      assignment: 'Group Booking: 5 rooms',
      startDate: '2024-03-01',
      endDate: '2024-03-03',
      status: 'confirmed'
    }
  ];

  const maintenanceSchedule = [
    {
      id: 1,
      type: 'vehicle',
      name: 'Mitsubishi Xpander',
      schedule: '2024-03-05',
      duration: '2 days',
      reason: 'Regular Maintenance',
      priority: 'normal'
    },
    {
      id: 2,
      type: 'hotel',
      name: 'Room 304',
      schedule: '2024-03-04',
      duration: '3 days',
      reason: 'AC Repair',
      priority: 'high'
    }
  ];

  const alerts = [
    {
      id: 1,
      type: 'vehicle',
      message: 'Toyota Hiace (N 1234 AB) maintenance due in 3 days',
      priority: 'warning'
    },
    {
      id: 2,
      type: 'guide',
      message: 'Guide certification renewal required for Michael Wong',
      priority: 'high'
    },
    {
      id: 3,
      type: 'hotel',
      message: 'Low room availability at Bromo Valley Hotel for next weekend',
      priority: 'info'
    }
  ];

  const resourceUtilization = [
    {
      date: '2024-03-01',
      vehicles: 75,
      guides: 80,
      hotels: 65
    },
    {
      date: '2024-03-02',
      vehicles: 85,
      guides: 90,
      hotels: 75
    },
    {
      date: '2024-03-03',
      vehicles: 70,
      guides: 85,
      hotels: 80
    }
    // Add more data points as needed
  ];

  return (
    <div className="resource-dashboard">
      {/* Header */}
      <div className="dashboard-header">
        <div>
          <h1>Resource Management</h1>
          <p>Overview and management of all resources</p>
        </div>
        <div className="header-actions">
          <div className="date-filter">
            <CalendarIcon size={20} />
            <select 
              value={dateRange}
              onChange={(e) => setDateRange(e.target.value)}
            >
              <option value="today">Today</option>
              <option value="week">This Week</option>
              <option value="month">This Month</option>
            </select>
          </div>
          <div className="search-bar">
            <Search size={20} />
            <input
              type="text"
              placeholder="Search resources..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
      </div>

      {/* Resource Stats Cards */}
      <div className="stats-grid">
        {/* Vehicle Stats */}
        <div className="stat-card">
          <div className="card-header">
            <div className="header-icon vehicle">
              <Truck size={24} />
            </div>
            <h3>Vehicles</h3>
          </div>
          <div className="stats-content">
            <div className="stat-row">
              <span>Total Vehicles</span>
              <span className="number">{stats.vehicles.total}</span>
            </div>
            <div className="stat-row">
              <span>Available</span>
              <span className="number available">{stats.vehicles.available}</span>
            </div>
            <div className="stat-row">
              <span>On Duty</span>
              <span className="number on-duty">{stats.vehicles.onDuty}</span>
            </div>
            <div className="stat-row">
              <span>Maintenance</span>
              <span className="number maintenance">{stats.vehicles.maintenance}</span>
            </div>
          </div>
        </div>

        {/* Guide Stats */}
        <div className="stat-card">
          <div className="card-header">
            <div className="header-icon guide">
              <Users size={24} />
            </div>
            <h3>Tour Guides</h3>
          </div>
          <div className="stats-content">
            <div className="stat-row">
              <span>Total Guides</span>
              <span className="number">{stats.guides.total}</span>
            </div>
            <div className="stat-row">
              <span>Available</span>
              <span className="number available">{stats.guides.available}</span>
            </div>
            <div className="stat-row">
              <span>On Duty</span>
              <span className="number on-duty">{stats.guides.onDuty}</span>
            </div>
            <div className="stat-row">
              <span>On Leave</span>
              <span className="number on-leave">{stats.guides.onLeave}</span>
            </div>
          </div>
        </div>

        {/* Hotel Stats */}
        <div className="stat-card">
          <div className="card-header">
            <div className="header-icon hotel">
              <Building2 size={24} />
            </div>
            <h3>Hotels</h3>
          </div>
          <div className="stats-content">
            <div className="stat-row">
              <span>Total Rooms</span>
              <span className="number">{stats.hotels.totalRooms}</span>
            </div>
            <div className="stat-row">
              <span>Available</span>
              <span className="number available">{stats.hotels.availableRooms}</span>
            </div>
            <div className="stat-row">
              <span>Booked</span>
              <span className="number booked">{stats.hotels.bookedRooms}</span>
            </div>
            <div className="stat-row">
              <span>Maintenance</span>
              <span className="number maintenance">{stats.hotels.maintenance}</span>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content Grid */}
      <div className="content-grid">
        {/* Upcoming Assignments */}
        <div className="content-card assignments">
          <div className="card-header">
            <h3>Upcoming Assignments</h3>
            <button className="view-all">View All</button>
          </div>
          <div className="card-content">
            {upcomingAssignments.map(assignment => (
              <div key={assignment.id} className="assignment-item">
                <div className="assignment-icon">
                  {assignment.type === 'vehicle' && <Truck size={20} />}
                  {assignment.type === 'guide' && <Users size={20} />}
                  {assignment.type === 'hotel' && <Building2 size={20} />}
                </div>
                <div className="assignment-info">
                  <h4>{assignment.name}</h4>
                  <p>{assignment.assignment}</p>
                  <div className="assignment-details">
                    <span className="time">
                      <Clock size={14} />
                      {new Date(assignment.startDate).toLocaleDateString()}
                    </span>
                    <span className={`status ${assignment.status}`}>
                      {assignment.status === 'confirmed' ? <CheckCircle2 size={14} /> : <Clock size={14} />}
                      {assignment.status.charAt(0).toUpperCase() + assignment.status.slice(1)}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Maintenance Schedule */}
        <div className="content-card maintenance">
          <div className="card-header">
            <h3>Maintenance Schedule</h3>
            <button className="view-all">View All</button>
          </div>
          <div className="card-content">
            {maintenanceSchedule.map(schedule => (
              <div key={schedule.id} className="maintenance-item">
                <div className={`priority-indicator ${schedule.priority}`} />
                <div className="maintenance-info">
                  <h4>{schedule.name}</h4>
                  <p>{schedule.reason}</p>
                  <div className="maintenance-details">
                    <span className="date">
                      <Calendar size={14} />
                      {new Date(schedule.schedule).toLocaleDateString()}
                    </span>
                    <span className="duration">
                      <Clock size={14} />
                      {schedule.duration}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Resource Alerts */}
        <div className="content-card alerts">
          <div className="card-header">
            <h3>Resource Alerts</h3>
            <button className="view-all">View All</button>
          </div>
          <div className="card-content">
            {alerts.map(alert => (
              <div key={alert.id} className={`alert-item ${alert.priority}`}>
                <div className="alert-icon">
                  {alert.priority === 'high' && <AlertCircle size={20} />}
                  {alert.priority === 'warning' && <AlertTriangle size={20} />}
                  {alert.priority === 'info' && <AlertCircle size={20} />}
                </div>
                <div className="alert-info">
                  <p>{alert.message}</p>
                  <div className="alert-type">
                    {alert.type.charAt(0).toUpperCase() + alert.type.slice(1)}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResourceDashboard;