import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Search, Plus, Star, MapPin, Phone, Globe, MoreVertical, Edit2, Trash2, Filter } from 'lucide-react';
import './Index.scss';

const HotelList = () => {
  const [filterStatus, setFilterStatus] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [viewMode, setViewMode] = useState('grid');
  const [openDropdownId, setOpenDropdownId] = useState(null);

  const hotels = [
    {
      id: 1,
      name: "Grand Bromo Hotel",
      image: "https://ik.imagekit.io/tvlk/apr-asset/dgXfoyh24ryQLRcGq00cIdKHRmotrWLNlvG-TxlcLxGkiDwaUSggleJNPRgIHCX6/hotel/asset/20013023-ae00cf9c00397976c5fb5b13769246e1.jpeg?_src=imagekit&tr=c-at_max,f-jpg,fo-auto,h-500,pr-true,q-80,w-740",
      rating: 4.8,
      type: "Business Hotel",
      status: "active",
      address: "Jl. Raya Bromo No. 123, Probolinggo",
      phone: "+62 812-3456-7890",
      email: "contact@grandbromo.com",
      website: "www.grandbromo.com",
      totalRooms: 120,
      availableRooms: 45,
      priceRange: "IDR 800K - 2.5M",
      amenities: ["Swimming Pool", "Restaurant", "Spa", "Fitness Center", "Meeting Rooms"],
      description: "Luxury hotel with magnificent views of Mount Bromo",
      partnerSince: "2020"
    },
    {
      id: 2,
      name: "Ijen Resort & Villas",
      image: "https://cf.bstatic.com/xdata/images/hotel/max1024x768/248931706.jpg?k=07e929b995611d973192ff6f3a3f85abb306377305d368b52866b52e56fd56b3&o=&hp=1",
      rating: 4.9,
      type: "Resort",
      status: "active",
      address: "Jl. Ijen Plateau Km. 15, Bondowoso",
      phone: "+62 813-5678-9012",
      email: "booking@ijenresort.com",
      website: "www.ijenresort.com",
      totalRooms: 75,
      availableRooms: 20,
      priceRange: "IDR 1.5M - 4M",
      amenities: ["Private Pool", "Restaurant", "Spa", "Garden", "Trekking Tours"],
      description: "Exclusive resort overlooking the Ijen crater",
      partnerSince: "2019"
    },
    {
      id: 3,
      name: "Malang City Hotel",
      image: "https://q-xx.bstatic.com/xdata/images/hotel/max500/596771732.jpg?k=c251620169bc0c6994ac40bed19a8cccd587654f3e0281dc5d3872d94091f26c&o=",
      rating: 4.5,
      type: "City Hotel",
      status: "maintenance",
      address: "Jl. Tugu No. 45, Malang",
      phone: "+62 814-7890-1234",
      email: "info@malangcityhotel.com",
      website: "www.malangcityhotel.com",
      totalRooms: 200,
      availableRooms: 0,
      priceRange: "IDR 500K - 1.2M",
      amenities: ["Restaurant", "Meeting Rooms", "Business Center", "Parking"],
      description: "Strategic city hotel in the heart of Malang",
      partnerSince: "2021"
    },
    {
      id: 4,
      name: "Tumpak Sewu Lodge",
      image: "https://muliatransjawa.com/wp-content/uploads/2024/05/Artha-Cottages-Hotel-Terbaik-di-Dekat-Tumpak-Sewu.jpg",
      rating: 4.7,
      type: "Lodge",
      status: "active",
      address: "Jl. Tumpak Sewu, Lumajang",
      phone: "+62 815-2345-6789",
      email: "reservation@tumpaksewu.com",
      website: "www.tumpaksewulodge.com",
      totalRooms: 40,
      availableRooms: 15,
      priceRange: "IDR 600K - 1.5M",
      amenities: ["Restaurant", "Waterfall View", "Guided Tours", "Parking"],
      description: "Cozy lodge near the magnificent Tumpak Sewu waterfall",
      partnerSince: "2022"
    }
  ];

  const statusColors = {
    active: { bg: 'bg-green-100', text: 'text-green-800' },
    maintenance: { bg: 'bg-orange-100', text: 'text-orange-800' },
    inactive: { bg: 'bg-red-100', text: 'text-red-800' }
  };

  const filteredHotels = hotels.filter(hotel => {
    const matchesSearch = hotel.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                         hotel.address.toLowerCase().includes(searchTerm.toLowerCase()) ||
                         hotel.type.toLowerCase().includes(searchTerm.toLowerCase());
    
    const matchesStatus = filterStatus === 'all' || hotel.status === filterStatus;
    
    return matchesSearch && matchesStatus;
  });

  const handleEdit = (hotelId) => {
    console.log('Edit hotel:', hotelId);
    setOpenDropdownId(null);
  };

  const handleDelete = (hotelId) => {
    console.log('Delete hotel:', hotelId);
    setOpenDropdownId(null);
  };

  return (
    <div className="hotel-list">
      {/* Header Section */}
      <div className="list-header">
        <div className="header-left">
          <h1>Hotels</h1>
          <p>Manage your partner hotels and accommodations</p>
        </div>
        <NavLink to="/resources/hotels/create" className="add-btn">
          <Plus size={20} />
          Add New Hotel
        </NavLink>
      </div>

      {/* Filters Section */}
      <div className="filters-section">
        <div className="search-bar">
          <Search size={20} />
          <input
            type="text"
            placeholder="Search hotels..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <div className="filter-buttons">
          <button
            className={`filter-btn ${filterStatus === 'all' ? 'active' : ''}`}
            onClick={() => setFilterStatus('all')}
          >
            All
          </button>
          <button
            className={`filter-btn ${filterStatus === 'active' ? 'active' : ''}`}
            onClick={() => setFilterStatus('active')}
          >
            Active
          </button>
          <button
            className={`filter-btn ${filterStatus === 'maintenance' ? 'active' : ''}`}
            onClick={() => setFilterStatus('maintenance')}
          >
            Maintenance
          </button>
          <button
            className={`filter-btn ${filterStatus === 'inactive' ? 'active' : ''}`}
            onClick={() => setFilterStatus('inactive')}
          >
            Inactive
          </button>
        </div>

        <div className="view-toggle">
          <button
            className={`toggle-btn ${viewMode === 'grid' ? 'active' : ''}`}
            onClick={() => setViewMode('grid')}
          >
            Grid
          </button>
          <button
            className={`toggle-btn ${viewMode === 'table' ? 'active' : ''}`}
            onClick={() => setViewMode('table')}
          >
            Table
          </button>
        </div>
      </div>

      {/* Grid View */}
      {viewMode === 'grid' && (
        <div className="hotels-grid">
          {filteredHotels.map(hotel => (
            <div key={hotel.id} className="hotel-card">
              <div className="card-image">
                <img src={hotel.image} alt={hotel.name} />
                <div className="image-overlay">
                  <span className={`status-badge ${statusColors[hotel.status].bg} ${statusColors[hotel.status].text}`}>
                    {hotel.status.charAt(0).toUpperCase() + hotel.status.slice(1)}
                  </span>
                  <div className="dropdown-container">
                    <button
                      className="more-btn"
                      onClick={() => setOpenDropdownId(openDropdownId === hotel.id ? null : hotel.id)}
                    >
                      <MoreVertical size={20} />
                    </button>
                    {openDropdownId === hotel.id && (
                      <div className="dropdown-menu">
                        <button onClick={() => handleEdit(hotel.id)}>
                          <Edit2 size={16} />
                          Edit
                        </button>
                        <button onClick={() => handleDelete(hotel.id)}>
                          <Trash2 size={16} />
                          Delete
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="card-content">
                <div className="hotel-info">
                  <h3>{hotel.name}</h3>
                  <span className="hotel-type">{hotel.type}</span>
                  <div className="rating">
                    <Star size={16} className="text-yellow-500" />
                    <span>{hotel.rating}</span>
                  </div>
                </div>

                <div className="hotel-details">
                  <div className="detail-item">
                    <MapPin size={16} />
                    <span>{hotel.address}</span>
                  </div>
                  <div className="detail-item">
                    <Phone size={16} />
                    <span>{hotel.phone}</span>
                  </div>
                  <div className="detail-item">
                    <Globe size={16} />
                    <span>{hotel.website}</span>
                  </div>
                </div>

                <div className="room-info">
                  <div className="info-item">
                    <label>Total Rooms</label>
                    <span>{hotel.totalRooms}</span>
                  </div>
                  <div className="info-item">
                    <label>Available</label>
                    <span>{hotel.availableRooms}</span>
                  </div>
                  <div className="info-item">
                    <label>Price Range</label>
                    <span>{hotel.priceRange}</span>
                  </div>
                </div>

                <div className="amenities">
                  <label>Amenities:</label>
                  <div className="amenities-list">
                    {hotel.amenities.map((amenity, index) => (
                      <span key={index} className="amenity-tag">{amenity}</span>
                    ))}
                  </div>
                </div>

                <div className="card-footer">
                  <button className="view-rooms-btn">View Rooms</button>
                  <button className="book-now-btn">Book Now</button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Table View */}
      {viewMode === 'table' && (
        <div className="hotels-table">
          <table>
            <thead>
              <tr>
                <th>Hotel</th>
                <th>Type</th>
                <th>Status</th>
                <th>Rooms</th>
                <th>Price Range</th>
                <th>Rating</th>
                <th>Partner Since</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredHotels.map(hotel => (
                <tr key={hotel.id}>
                  <td>
                    <div className="hotel-cell">
                      <img src={hotel.image} alt={hotel.name} />
                      <div>
                        <span className="name">{hotel.name}</span>
                        <span className="address">{hotel.address}</span>
                      </div>
                    </div>
                  </td>
                  <td>{hotel.type}</td>
                  <td>
                    <span className={`status-badge ${statusColors[hotel.status].bg} ${statusColors[hotel.status].text}`}>
                      {hotel.status}
                    </span>
                  </td>
                  <td>
                    <div className="rooms-info">
                      <span className="total">{hotel.totalRooms}</span>
                      <span className="available">({hotel.availableRooms} available)</span>
                    </div>
                  </td>
                  <td>{hotel.priceRange}</td>
                  <td>
                    <div className="rating">
                      <Star size={16} className="text-yellow-500" />
                      <span>{hotel.rating}</span>
                    </div>
                  </td>
                  <td>{hotel.partnerSince}</td>
                  <td>
                    <div className="action-buttons">
                      <button className="action-btn" onClick={() => handleEdit(hotel.id)}>View</button>
                      <button className="action-btn" onClick={() => handleEdit(hotel.id)}>Edit</button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default HotelList;