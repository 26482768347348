import React, { useState } from 'react';
import { Save, X, Upload, Plus, Trash2 } from 'lucide-react';
import './Create.scss';

const HotelCreate = () => {
  const [formData, setFormData] = useState({
    name: '',
    type: '',
    address: '',
    phone: '',
    email: '',
    website: '',
    description: '',
    totalRooms: '',
    priceRange: {
      min: '',
      max: ''
    },
    status: 'active',
    amenities: [],
    images: [],
    policies: {
      checkIn: '',
      checkOut: '',
      cancellation: ''
    }
  });

  const [errors, setErrors] = useState({});
  const [imagePreview, setImagePreview] = useState(null);

  const hotelTypes = [
    'Business Hotel',
    'Resort',
    'City Hotel',
    'Lodge',
    'Boutique Hotel',
    'Villa',
    'Guest House'
  ];

  const commonAmenities = [
    'Swimming Pool',
    'Restaurant',
    'Spa',
    'Fitness Center',
    'Meeting Rooms',
    'Free WiFi',
    'Room Service',
    'Bar/Lounge',
    'Airport Shuttle',
    'Parking',
    'Business Center',
    'Laundry Service'
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    // Clear error when user types
    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: '' }));
    }
  };

  const handlePriceRangeChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      priceRange: {
        ...prev.priceRange,
        [name]: value
      }
    }));
  };

  const handlePolicyChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      policies: {
        ...prev.policies,
        [name]: value
      }
    }));
  };

  const handleAmenityToggle = (amenity) => {
    setFormData(prev => {
      if (prev.amenities.includes(amenity)) {
        return {
          ...prev,
          amenities: prev.amenities.filter(a => a !== amenity)
        };
      } else {
        return {
          ...prev,
          amenities: [...prev.amenities, amenity]
        };
      }
    });
  };

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    const newImages = files.map(file => ({
      file,
      preview: URL.createObjectURL(file)
    }));
    
    setFormData(prev => ({
      ...prev,
      images: [...prev.images, ...newImages]
    }));
  };

  const removeImage = (index) => {
    setFormData(prev => ({
      ...prev,
      images: prev.images.filter((_, i) => i !== index)
    }));
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.name.trim()) newErrors.name = 'Hotel name is required';
    if (!formData.type) newErrors.type = 'Hotel type is required';
    if (!formData.address.trim()) newErrors.address = 'Address is required';
    if (!formData.phone.trim()) newErrors.phone = 'Phone number is required';
    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Invalid email format';
    }
    if (!formData.totalRooms) newErrors.totalRooms = 'Total rooms is required';
    if (!formData.priceRange.min || !formData.priceRange.max) {
      newErrors.priceRange = 'Price range is required';
    }
    if (formData.amenities.length === 0) {
      newErrors.amenities = 'Select at least one amenity';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      console.log('Form submitted:', formData);
      // Handle form submission
    }
  };

  return (
    <div className="hotel-create">
      <div className="form-header">
        <div>
          <h1>Add New Hotel</h1>
          <p>Enter the hotel information below</p>
        </div>
        <div className="header-buttons">
          <button type="button" className="cancel-btn">
            <X size={20} />
            Cancel
          </button>
          <button type="button" className="save-btn" onClick={handleSubmit}>
            <Save size={20} />
            Save
          </button>
        </div>
      </div>

      <form className="hotel-form">
        <div className="form-grid">
          {/* Left Column */}
          <div className="form-left">
            {/* Basic Information */}
            <div className="form-section">
              <h2>Basic Information</h2>

              <div className="form-group">
                <label htmlFor="name">Hotel Name*</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  className={errors.name ? 'error' : ''}
                />
                {errors.name && <span className="error-message">{errors.name}</span>}
              </div>

              <div className="form-group">
                <label htmlFor="type">Hotel Type*</label>
                <select
                  id="type"
                  name="type"
                  value={formData.type}
                  onChange={handleInputChange}
                  className={errors.type ? 'error' : ''}
                >
                  <option value="">Select hotel type</option>
                  {hotelTypes.map(type => (
                    <option key={type} value={type}>{type}</option>
                  ))}
                </select>
                {errors.type && <span className="error-message">{errors.type}</span>}
              </div>

              <div className="form-group">
                <label htmlFor="description">Description</label>
                <textarea
                  id="description"
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                  rows="4"
                />
              </div>
            </div>

            {/* Contact Information */}
            <div className="form-section">
              <h2>Contact Information</h2>

              <div className="form-group">
                <label htmlFor="address">Address*</label>
                <input
                  type="text"
                  id="address"
                  name="address"
                  value={formData.address}
                  onChange={handleInputChange}
                  className={errors.address ? 'error' : ''}
                />
                {errors.address && <span className="error-message">{errors.address}</span>}
              </div>

              <div className="form-group">
                <label htmlFor="phone">Phone Number*</label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  className={errors.phone ? 'error' : ''}
                />
                {errors.phone && <span className="error-message">{errors.phone}</span>}
              </div>

              <div className="form-group">
                <label htmlFor="email">Email Address*</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className={errors.email ? 'error' : ''}
                />
                {errors.email && <span className="error-message">{errors.email}</span>}
              </div>

              <div className="form-group">
                <label htmlFor="website">Website</label>
                <input
                  type="url"
                  id="website"
                  name="website"
                  value={formData.website}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>

          {/* Right Column */}
          <div className="form-right">
            {/* Images */}
            <div className="form-section">
              <h2>Hotel Images</h2>
              <div className="images-grid">
                {formData.images.map((image, index) => (
                  <div key={index} className="image-item">
                    <img src={image.preview} alt={`Hotel ${index + 1}`} />
                    <button
                      type="button"
                      className="remove-image"
                      onClick={() => removeImage(index)}
                    >
                      <Trash2 size={16} />
                    </button>
                  </div>
                ))}
                <label className="image-upload">
                  <input
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={handleImageUpload}
                    hidden
                  />
                  <div className="upload-placeholder">
                    <Plus size={24} />
                    <span>Add Image</span>
                  </div>
                </label>
              </div>
            </div>

            {/* Room Information */}
            <div className="form-section">
              <h2>Room Information</h2>

              <div className="form-group">
                <label htmlFor="totalRooms">Total Rooms*</label>
                <input
                  type="number"
                  id="totalRooms"
                  name="totalRooms"
                  value={formData.totalRooms}
                  onChange={handleInputChange}
                  min="1"
                  className={errors.totalRooms ? 'error' : ''}
                />
                {errors.totalRooms && <span className="error-message">{errors.totalRooms}</span>}
              </div>

              <div className="form-group">
                <label>Price Range*</label>
                <div className="price-range">
                  <input
                    type="number"
                    name="min"
                    value={formData.priceRange.min}
                    onChange={handlePriceRangeChange}
                    placeholder="Min"
                    min="0"
                  />
                  <span>to</span>
                  <input
                    type="number"
                    name="max"
                    value={formData.priceRange.max}
                    onChange={handlePriceRangeChange}
                    placeholder="Max"
                    min="0"
                  />
                </div>
                {errors.priceRange && <span className="error-message">{errors.priceRange}</span>}
              </div>
            </div>

            {/* Amenities */}
            <div className="form-section">
              <h2>Amenities</h2>
              <div className="amenities-grid">
                {commonAmenities.map(amenity => (
                  <label key={amenity} className="amenity-checkbox">
                    <input
                      type="checkbox"
                      checked={formData.amenities.includes(amenity)}
                      onChange={() => handleAmenityToggle(amenity)}
                    />
                    <span>{amenity}</span>
                  </label>
                ))}
              </div>
              {errors.amenities && <span className="error-message">{errors.amenities}</span>}
            </div>

            {/* Policies */}
            <div className="form-section">
              <h2>Policies</h2>
              <div className="form-group">
                <label htmlFor="checkIn">Check-in Time</label>
                <input
                  type="time"
                  id="checkIn"
                  name="checkIn"
                  value={formData.policies.checkIn}
                  onChange={handlePolicyChange}
                />
              </div>

              <div className="form-group">
                <label htmlFor="checkOut">Check-out Time</label>
                <input
                  type="time"
                  id="checkOut"
                  name="checkOut"
                  value={formData.policies.checkOut}
                  onChange={handlePolicyChange}
                />
              </div>

              <div className="form-group">
                <label htmlFor="cancellation">Cancellation Policy</label>
                <textarea
                  id="cancellation"
                  name="cancellation"
                  value={formData.policies.cancellation}
                  onChange={handlePolicyChange}
                  rows="3"
                />
              </div>
            </div>

            {/* Status */}
            <div className="form-section">
              <h2>Status</h2>
              <div className="status-options">
                {['active', 'maintenance', 'inactive'].map(status => (
                  <label key={status} className="status-radio">
                    <input
                      type="radio"
                      name="status"
                      value={status}
                      checked={formData.status === status}
                      onChange={handleInputChange}
                    />
                    <span>{status.charAt(0).toUpperCase() + status.slice(1)}</span>
                  </label>
                ))}
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default HotelCreate;