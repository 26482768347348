import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import { Search, Plus, MoreVertical, Edit2, Trash2, Users, Calendar, Clock, Settings, Car, Info } from 'lucide-react';
import './Index.scss';

const Transportation = () => {
  const [filterStatus, setFilterStatus] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [viewMode, setViewMode] = useState('grid');
  const [openDropdownId, setOpenDropdownId] = useState(null);

  const vehicles = [
    {
      id: 1,
      name: "Toyota Hiace",
      image: "https://www.toyota.astra.co.id//sites/default/files/2022-04/2%20hiace%20premio%20white.png",
      type: "Van",
      capacity: 12,
      plateNumber: "N 1234 AB",
      status: "available",
      lastMaintenance: "2024-02-15",
      nextMaintenance: "2024-03-15",
      mileage: 45000,
      fuelType: "Diesel",
      features: ["AC", "WiFi", "USB Charging", "Luggage Space"],
      driver: {
        name: "John Smith",
        phone: "+62 812-3456-7890",
        license: "SIM B1",
        experience: "5 years"
      },
      upcoming: [
        {
          date: "2024-03-01",
          tour: "Bromo Sunrise Tour",
          duration: "3 days"
        }
      ]
    },
    {
      id: 2,
      name: "Mitsubishi Xpander",
      image: "https://imgx.gridoto.com/crop/0x0:0x0/700x465/filters:watermark(file/2017/gridoto/img/watermark_otomotifnet.png,5,5,60)/photo/2024/02/02/whatsapp-image-2024-02-02-at-82-20240202082449.jpeg",
      type: "MPV",
      capacity: 6,
      plateNumber: "N 5678 BC",
      status: "maintenance",
      lastMaintenance: "2024-02-20",
      nextMaintenance: "2024-03-20",
      mileage: 32000,
      fuelType: "Petrol",
      features: ["AC", "Bluetooth", "USB Charging"],
      driver: {
        name: "David Chen",
        phone: "+62 813-5678-9012",
        license: "SIM B1",
        experience: "3 years"
      },
      upcoming: []
    },
    {
      id: 3,
      name: "Isuzu ELF",
      image: "https://otoklix-production.s3.amazonaws.com/uploads/2022/04/mobil-elf.jpg",
      type: "Mini Bus",
      capacity: 16,
      plateNumber: "N 9012 CD",
      status: "available",
      lastMaintenance: "2024-02-10",
      nextMaintenance: "2024-03-10",
      mileage: 55000,
      fuelType: "Diesel",
      features: ["AC", "WiFi", "USB Charging", "Microphone", "TV"],
      driver: {
        name: "Robert Lee",
        phone: "+62 814-7890-1234",
        license: "SIM B2",
        experience: "7 years"
      },
      upcoming: [
        {
          date: "2024-03-05",
          tour: "Malang City Tour",
          duration: "1 day"
        }
      ]
    },
    {
      id: 4,
      name: "Toyota Innova",
      image: "https://www.doktermobil.co.id/wp-content/uploads/2024/08/Jepretan-Layar-2024-08-02-pukul-13.40.47-1030x609.png",
      type: "MPV",
      capacity: 7,
      plateNumber: "N 3456 DE",
      status: "on_trip",
      lastMaintenance: "2024-02-18",
      nextMaintenance: "2024-03-18",
      mileage: 28000,
      fuelType: "Diesel",
      features: ["AC", "WiFi", "USB Charging", "GPS Navigation"],
      driver: {
        name: "Michael Wong",
        phone: "+62 815-2345-6789",
        license: "SIM B1",
        experience: "4 years"
      },
      upcoming: [
        {
          date: "2024-03-02",
          tour: "Ijen Crater Tour",
          duration: "2 days"
        }
      ]
    }
  ];

  const statusColors = {
    available: { bg: 'bg-green-100', text: 'text-green-800', label: 'Available' },
    maintenance: { bg: 'bg-orange-100', text: 'text-orange-800', label: 'Under Maintenance' },
    on_trip: { bg: 'bg-blue-100', text: 'text-blue-800', label: 'On Trip' },
    inactive: { bg: 'bg-red-100', text: 'text-red-800', label: 'Inactive' }
  };

  const filteredVehicles = vehicles.filter(vehicle => {
    const matchesSearch = 
      vehicle.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      vehicle.type.toLowerCase().includes(searchTerm.toLowerCase()) ||
      vehicle.plateNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
      vehicle.driver.name.toLowerCase().includes(searchTerm.toLowerCase());
    
    const matchesStatus = filterStatus === 'all' || vehicle.status === filterStatus;
    
    return matchesSearch && matchesStatus;
  });

  const getMaintenanceStatus = (lastMaintenance, nextMaintenance) => {
    const today = new Date();
    const next = new Date(nextMaintenance);
    const diff = Math.ceil((next - today) / (1000 * 60 * 60 * 24));

    if (diff <= 7) {
      return { status: 'warning', message: `Due in ${diff} days` };
    }
    return { status: 'good', message: `Due ${next.toLocaleDateString()}` };
  };

  const handleAction = (action, vehicleId) => {
    console.log(`${action} vehicle:`, vehicleId);
    setOpenDropdownId(null);
  };

  return (
    <div className="transportation-list">
      {/* Header */}
      <div className="list-header">
        <div className="header-left">
          <h1>Transportation</h1>
          <p>Manage your vehicles and drivers</p>
        </div>
        <NavLink to="/resources/vehicles/create" className="add-btn">
          <Plus size={20} />
          Add New Vehicle
        </NavLink>
      </div>

      {/* Filters */}
      <div className="filters-section">
        <div className="search-bar">
          <Search size={20} />
          <input
            type="text"
            placeholder="Search vehicles, drivers, or plate numbers..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <div className="filter-buttons">
          <button
            className={`filter-btn ${filterStatus === 'all' ? 'active' : ''}`}
            onClick={() => setFilterStatus('all')}
          >
            All Vehicles
          </button>
          <button
            className={`filter-btn ${filterStatus === 'available' ? 'active' : ''}`}
            onClick={() => setFilterStatus('available')}
          >
            Available
          </button>
          <button
            className={`filter-btn ${filterStatus === 'on_trip' ? 'active' : ''}`}
            onClick={() => setFilterStatus('on_trip')}
          >
            On Trip
          </button>
          <button
            className={`filter-btn ${filterStatus === 'maintenance' ? 'active' : ''}`}
            onClick={() => setFilterStatus('maintenance')}
          >
            Maintenance
          </button>
        </div>

        <div className="view-toggle">
          <button
            className={`toggle-btn ${viewMode === 'grid' ? 'active' : ''}`}
            onClick={() => setViewMode('grid')}
          >
            Grid
          </button>
          <button
            className={`toggle-btn ${viewMode === 'table' ? 'active' : ''}`}
            onClick={() => setViewMode('table')}
          >
            Table
          </button>
        </div>
      </div>

      {/* Grid View */}
      {viewMode === 'grid' && (
        <div className="vehicles-grid">
          {filteredVehicles.map(vehicle => (
            <div key={vehicle.id} className="vehicle-card">
              <div className="card-header">
                <img src={vehicle.image} alt={vehicle.name} />
                <div className="header-overlay">
                  <span className={`status-badge ${statusColors[vehicle.status].bg} ${statusColors[vehicle.status].text}`}>
                    {statusColors[vehicle.status].label}
                  </span>
                  <div className="dropdown-wrapper">
                    <button
                      className="more-btn"
                      onClick={() => setOpenDropdownId(openDropdownId === vehicle.id ? null : vehicle.id)}
                    >
                      <MoreVertical size={20} />
                    </button>
                    {openDropdownId === vehicle.id && (
                      <div className="dropdown-menu">
                        <button onClick={() => handleAction('edit', vehicle.id)}>
                          <Edit2 size={16} />
                          Edit
                        </button>
                        <button onClick={() => handleAction('delete', vehicle.id)} className="text-red-600">
                          <Trash2 size={16} />
                          Delete
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="card-content">
                <div className="vehicle-info">
                  <h3>{vehicle.name}</h3>
                  <span className="plate-number">{vehicle.plateNumber}</span>
                  <div className="type-capacity">
                    <Car size={16} />
                    <span>{vehicle.type} • {vehicle.capacity} seats</span>
                  </div>
                </div>

                <div className="info-grid">
                  <div className="info-item">
                    <label>Mileage</label>
                    <span>{vehicle.mileage.toLocaleString()} km</span>
                  </div>
                  <div className="info-item">
                    <label>Fuel Type</label>
                    <span>{vehicle.fuelType}</span>
                  </div>
                  <div className="info-item">
                    <label>Next Maintenance</label>
                    <span className={getMaintenanceStatus(vehicle.lastMaintenance, vehicle.nextMaintenance).status}>
                      {getMaintenanceStatus(vehicle.lastMaintenance, vehicle.nextMaintenance).message}
                    </span>
                  </div>
                </div>

                <div className="driver-info">
                  <h4>Assigned Driver</h4>
                  <div className="driver-details">
                    <div>
                      <span className="name">{vehicle.driver.name}</span>
                      <span className="experience">{vehicle.driver.experience} experience</span>
                    </div>
                    <span className="phone">{vehicle.driver.phone}</span>
                  </div>
                </div>

                {vehicle.upcoming.length > 0 && (
                  <div className="upcoming-trips">
                    <h4>Upcoming Trip</h4>
                    {vehicle.upcoming.map((trip, index) => (
                      <div key={index} className="trip-info">
                        <div className="trip-date">
                          <Calendar size={16} />
                          <span>{trip.date}</span>
                        </div>
                        <div className="trip-details">
                          <span className="tour">{trip.tour}</span>
                          <span className="duration">
                            <Clock size={14} />
                            {trip.duration}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                )}

                <div className="features">
                  {vehicle.features.map((feature, index) => (
                    <span key={index} className="feature-tag">{feature}</span>
                  ))}
                </div>
              </div>

              <div className="card-footer">
                <button className="details-btn">
                  <Info size={16} />
                  View Details
                </button>
                <button className="maintain-btn">
                  <Settings size={16} />
                  Maintenance Log
                </button>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Table View */}
      {viewMode === 'table' && (
        <div className="vehicles-table">
          <table>
            <thead>
              <tr>
                <th>Vehicle</th>
                <th>Driver</th>
                <th>Status</th>
                <th>Mileage</th>
                <th>Next Maintenance</th>
                <th>Upcoming Trip</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredVehicles.map(vehicle => (
                <tr key={vehicle.id}>
                  <td>
                    <div className="vehicle-cell">
                      <img src={vehicle.image} alt={vehicle.name} />
                      <div>
                        <span className="name">{vehicle.name}</span>
                        <span className="details">
                          {vehicle.type} • {vehicle.plateNumber}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="driver-cell">
                      <span className="name">{vehicle.driver.name}</span>
                      <span className="phone">{vehicle.driver.phone}</span>
                    </div>
                  </td>
                  <td>
                    <span className={`status-badge ${statusColors[vehicle.status].bg} ${statusColors[vehicle.status].text}`}>
                      {statusColors[vehicle.status].label}
                    </span>
                  </td>
                  <td>{vehicle.mileage.toLocaleString()} km</td>
                  <td>
                    <span className={`maintenance-status ${getMaintenanceStatus(vehicle.lastMaintenance, vehicle.nextMaintenance).status}`}>
                      {getMaintenanceStatus(vehicle.lastMaintenance, vehicle.nextMaintenance).message}
                    </span>
                  </td>
                  <td>
                    {vehicle.upcoming.length > 0 ? (
                      <div className="upcoming-cell">
                        <span className="tour">{vehicle.upcoming[0].tour}</span>
                        <span className="date">{vehicle.upcoming[0].date}</span>
                      </div>
                    ) : (
                      <span className="no-trip">No upcoming trips</span>
                    )}
                  </td>
                  <td>
                    <div className="action-buttons">
                      <button className="action-btn view-btn" onClick={() => handleAction('view', vehicle.id)}>
                        View
                      </button>
                      <button className="action-btn edit-btn" onClick={() => handleAction('edit', vehicle.id)}>
                        Edit
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Transportation;