import React, { useState, useEffect } from 'react';
import { Calendar, Users, Filter, Search, MapPin, Clock, Calendar as CalendarIcon, ChevronLeft, ChevronRight, MoreVertical } from 'lucide-react';
import './Index.scss';

const CrewSchedule = () => {
  const [view, setView] = useState('month'); // month, week, list
  const [currentDate, setCurrentDate] = useState(new Date());
  const [filterStatus, setFilterStatus] = useState('all');
  const [filterType, setFilterType] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedEvent, setSelectedEvent] = useState(null);

  // Sample data for crew schedules
  const crewSchedules = [
    {
      id: 1,
      crewName: "John Smith",
      type: "guide",
      role: "Senior Guide",
      tour: "Bromo Sunrise Tour",
      startDate: "2024-03-01T04:00:00",
      endDate: "2024-03-03T14:00:00",
      location: "Bromo",
      status: "assigned",
      guests: 8,
      avatar: "https://static-00.iconduck.com/assets.00/avatar-default-icon-2048x2048-h6w375ur.png",
      languages: ["English", "Indonesian"],
      contact: "+62 812-3456-7890"
    },
    {
      id: 2,
      crewName: "Sarah Johnson",
      type: "guide",
      role: "Tour Guide",
      tour: "Ijen Crater Tour",
      startDate: "2024-03-02T00:00:00",
      endDate: "2024-03-04T12:00:00",
      location: "Ijen",
      status: "ongoing",
      guests: 6,
      avatar: "https://static-00.iconduck.com/assets.00/avatar-default-icon-2048x2048-h6w375ur.png",
      languages: ["English", "Japanese"],
      contact: "+62 813-5678-9012"
    },
    {
      id: 3,
      crewName: "David Chen",
      type: "guide",
      role: "Local Guide",
      tour: "Malang City Tour",
      startDate: "2024-03-05T08:00:00",
      endDate: "2024-03-05T18:00:00",
      location: "Malang",
      status: "upcoming",
      guests: 4,
      avatar: "https://static-00.iconduck.com/assets.00/avatar-default-icon-2048x2048-h6w375ur.png",
      languages: ["English", "Mandarin"],
      contact: "+62 814-7890-1234"
    }
  ];

  // Filter states for crew type
  const crewTypes = [
    { value: 'all', label: 'All Crew' },
    { value: 'guide', label: 'Tour Guides' },
    { value: 'driver', label: 'Drivers' },
    { value: 'helper', label: 'Support Staff' }
  ];

  // Filter states for schedule status
  const statusTypes = [
    { value: 'all', label: 'All Status' },
    { value: 'assigned', label: 'Assigned' },
    { value: 'ongoing', label: 'Ongoing' },
    { value: 'upcoming', label: 'Upcoming' },
    { value: 'completed', label: 'Completed' }
  ];

  const statusColors = {
    assigned: { bg: 'bg-blue-100', text: 'text-blue-800' },
    ongoing: { bg: 'bg-green-100', text: 'text-green-800' },
    upcoming: { bg: 'bg-yellow-100', text: 'text-yellow-800' },
    completed: { bg: 'bg-gray-100', text: 'text-gray-800' }
  };

  const formatDate = (dateString) => {
    const options = {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    };
    return new Date(dateString).toLocaleString('en-US', options);
  };

  // Navigation functions
  const nextPeriod = () => {
    const newDate = new Date(currentDate);
    if (view === 'month') {
      newDate.setMonth(currentDate.getMonth() + 1);
    } else if (view === 'week') {
      newDate.setDate(currentDate.getDate() + 7);
    }
    setCurrentDate(newDate);
  };

  const prevPeriod = () => {
    const newDate = new Date(currentDate);
    if (view === 'month') {
      newDate.setMonth(currentDate.getMonth() - 1);
    } else if (view === 'week') {
      newDate.setDate(currentDate.getDate() - 7);
    }
    setCurrentDate(newDate);
  };

  const getCurrentPeriodLabel = () => {
    const options = {
      month: { month: 'long', year: 'numeric' },
      week: { month: 'short', day: 'numeric' }
    };
    return currentDate.toLocaleDateString('en-US', options[view]);
  };

  // Filter function
  const getFilteredSchedules = () => {
    return crewSchedules.filter(schedule => {
      const matchesSearch = 
        schedule.crewName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        schedule.tour.toLowerCase().includes(searchTerm.toLowerCase()) ||
        schedule.location.toLowerCase().includes(searchTerm.toLowerCase());
      
      const matchesType = filterType === 'all' || schedule.type === filterType;
      const matchesStatus = filterStatus === 'all' || schedule.status === filterStatus;

      return matchesSearch && matchesType && matchesStatus;
    });
  };

  return (
    <div className="crew-schedule">
      {/* Header */}
      <div className="schedule-header">
        <div className="header-left">
          <h1>Crew Schedule</h1>
          <p>Manage tour guides and staff assignments</p>
        </div>

        <div className="header-actions">
          <button className="new-schedule-btn">
            <Calendar size={20} />
            New Assignment
          </button>
        </div>
      </div>

      {/* Filters */}
      <div className="schedule-filters">
        <div className="filters-left">
          <div className="view-switcher">
            <button 
              className={`view-btn ${view === 'month' ? 'active' : ''}`}
              onClick={() => setView('month')}
            >
              Month
            </button>
            <button 
              className={`view-btn ${view === 'week' ? 'active' : ''}`}
              onClick={() => setView('week')}
            >
              Week
            </button>
            <button 
              className={`view-btn ${view === 'list' ? 'active' : ''}`}
              onClick={() => setView('list')}
            >
              List
            </button>
          </div>

          <div className="period-selector">
            <button onClick={prevPeriod} className="nav-btn">
              <ChevronLeft size={20} />
            </button>
            <span className="current-period">{getCurrentPeriodLabel()}</span>
            <button onClick={nextPeriod} className="nav-btn">
              <ChevronRight size={20} />
            </button>
          </div>
        </div>

        <div className="filters-right">
          <div className="filter-group">
            <select 
              value={filterType} 
              onChange={(e) => setFilterType(e.target.value)}
              className="filter-select"
            >
              {crewTypes.map(type => (
                <option key={type.value} value={type.value}>
                  {type.label}
                </option>
              ))}
            </select>

            <select 
              value={filterStatus} 
              onChange={(e) => setFilterStatus(e.target.value)}
              className="filter-select"
            >
              {statusTypes.map(status => (
                <option key={status.value} value={status.value}>
                  {status.label}
                </option>
              ))}
            </select>
          </div>

          <div className="search-bar">
            <Search size={20} />
            <input
              type="text"
              placeholder="Search crew or tours..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
      </div>

      {/* Schedule List View */}
      {view === 'list' && (
        <div className="schedule-list">
          {getFilteredSchedules().map(schedule => (
            <div key={schedule.id} className="schedule-card">
              <div className="card-header">
                <div className="crew-info">
                  <img src={schedule.avatar} alt={schedule.crewName} className="crew-avatar" />
                  <div>
                    <h3>{schedule.crewName}</h3>
                    <span className="crew-role">{schedule.role}</span>
                  </div>
                </div>
                <div className={`status-badge ${statusColors[schedule.status].bg} ${statusColors[schedule.status].text}`}>
                  {schedule.status.charAt(0).toUpperCase() + schedule.status.slice(1)}
                </div>
              </div>

              <div className="card-content">
                <div className="tour-details">
                  <h4>{schedule.tour}</h4>
                  <div className="detail-item">
                    <MapPin size={16} />
                    <span>{schedule.location}</span>
                  </div>
                  <div className="detail-item">
                    <Clock size={16} />
                    <span>
                      {formatDate(schedule.startDate)} - {formatDate(schedule.endDate)}
                    </span>
                  </div>
                  <div className="detail-item">
                    <Users size={16} />
                    <span>{schedule.guests} guests</span>
                  </div>
                </div>

                <div className="crew-details">
                  <div className="languages">
                    <span className="label">Languages:</span>
                    {schedule.languages.map(lang => (
                      <span key={lang} className="language-tag">{lang}</span>
                    ))}
                  </div>
                  <div className="contact">
                    <span className="label">Contact:</span>
                    <span>{schedule.contact}</span>
                  </div>
                </div>
              </div>

              <div className="card-actions">
                <button className="view-btn">View Details</button>
                <button className="more-btn">
                  <MoreVertical size={16} />
                </button>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Calendar Views will be added in the next response */}
      {view === 'month' && (
        <div className="schedule-calendar month-view">
          {/* Month view implementation */}
        </div>
      )}

      {view === 'week' && (
        <div className="schedule-calendar week-view">
          {/* Week view implementation */}
        </div>
      )}
    </div>
  );
};

export default CrewSchedule;
