import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Sidebar from './components/Sidebar/Sidebar';

// Import all pages
import Dashboard from './pages/Dashboard';
import Crew from './pages/Crew/Index';
import CreateCrew from './pages/Crew/Create';
import Hotels from './pages/Hotels/Index';
import CreateHotels from './pages/Hotels/Create';
import Vehicles from './pages/Vehicles/Index';
import CreateVehicles from './pages/Vehicles/Create';
import ResourceDashboard from './pages/ResourceDashboard/Index';
import CrewSchedule from './pages/CrewSchedule/Index';
import Client from './pages/Clients/Index';
import TourPackages from './pages/TourPackages/Index';
import Booking from './pages/Bookings/Index';

import './App.scss';

const AppContent = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const location = useLocation();

  useEffect(() => {
    // Close sidebar if path is /bookings
    if (location.pathname === '/bookings') {
      setSidebarOpen(false);
    }
  }, [location.pathname]);

  const toggleSidebar = () => {
    setSidebarOpen(prev => !prev);
  };

  return (
    <div className="app">
      {/* Navbar */}
      <Navbar toggleSidebar={toggleSidebar} />
      
      {/* Sidebar */}
      <Sidebar isOpen={sidebarOpen} />

      {/* Main Content */}
      <main className={`main-content ${sidebarOpen ? 'sidebar-expanded' : ''}`}>
        <div className="page-container">
          <Routes>
            {/* Dashboard */}
            <Route path="/" element={<Dashboard />} />
            <Route path="/resources" element={<ResourceDashboard />} />
            <Route path="/resources/schedule" element={<CrewSchedule />} />
            <Route path="/resources/crews" element={<Crew />} />
            <Route path="/resources/crews/create" element={<CreateCrew />} />
            <Route path="/resources/hotels" element={<Hotels />} />
            <Route path="/resources/hotels/create" element={<CreateHotels />} />
            <Route path="/resources/vehicles" element={<Vehicles />} />
            <Route path="/resources/vehicles/create" element={<CreateVehicles />} />
            
            <Route path="/clients" element={<Client />} />

            <Route path="/itineraries/templates" element={<TourPackages />} />
            
            <Route path="/bookings" element={<Booking />} />
          </Routes>
        </div>
      </main>
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <AppContent />
    </Router>
  );
};

export default App;
