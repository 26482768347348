import React, { useState } from 'react';
import { Save, X, Upload, Plus, Calendar, Clock, Trash2 } from 'lucide-react';
import './Create.scss';

const TransportationForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    type: '',
    plateNumber: '',
    capacity: '',
    mileage: '',
    fuelType: '',
    features: [],
    status: 'available',
    maintenanceInfo: {
      lastMaintenance: '',
      nextMaintenance: '',
      notes: ''
    },
    driver: {
      name: '',
      phone: '',
      license: '',
      experience: '',
      address: '',
      emergencyContact: ''
    },
    documents: {
      vehicleRegistration: '',
      insurance: '',
      lastInspection: ''
    },
    images: []
  });

  const [errors, setErrors] = useState({});

  const vehicleTypes = [
    'Van',
    'MPV',
    'Mini Bus',
    'SUV',
    'Sedan',
    'Bus'
  ];

  const fuelTypes = [
    'Petrol',
    'Diesel',
    'Electric',
    'Hybrid'
  ];

  const commonFeatures = [
    'AC',
    'WiFi',
    'USB Charging',
    'GPS Navigation',
    'Bluetooth',
    'Luggage Space',
    'TV/DVD',
    'Microphone',
    'Reclining Seats',
    'Safety Kit',
    'First Aid Kit',
    'Fire Extinguisher'
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    // Clear error when user types
    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: '' }));
    }
  };

  const handleDriverChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      driver: {
        ...prev.driver,
        [name]: value
      }
    }));
  };

  const handleMaintenanceChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      maintenanceInfo: {
        ...prev.maintenanceInfo,
        [name]: value
      }
    }));
  };

  const handleFeatureToggle = (feature) => {
    setFormData(prev => {
      if (prev.features.includes(feature)) {
        return {
          ...prev,
          features: prev.features.filter(f => f !== feature)
        };
      }
      return {
        ...prev,
        features: [...prev.features, feature]
      };
    });
  };

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    const newImages = files.map(file => ({
      file,
      preview: URL.createObjectURL(file)
    }));
    
    setFormData(prev => ({
      ...prev,
      images: [...prev.images, ...newImages]
    }));
  };

  const removeImage = (index) => {
    setFormData(prev => ({
      ...prev,
      images: prev.images.filter((_, i) => i !== index)
    }));
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.name.trim()) newErrors.name = 'Vehicle name is required';
    if (!formData.type) newErrors.type = 'Vehicle type is required';
    if (!formData.plateNumber.trim()) newErrors.plateNumber = 'Plate number is required';
    if (!formData.capacity) newErrors.capacity = 'Capacity is required';
    if (!formData.driver.name.trim()) newErrors.driverName = 'Driver name is required';
    if (!formData.driver.phone.trim()) newErrors.driverPhone = 'Driver phone is required';
    if (!formData.driver.license.trim()) newErrors.driverLicense = 'Driver license is required';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      console.log('Form submitted:', formData);
      // Handle form submission
    }
  };

  return (
    <div className="transportation-form">
      {/* Header */}
      <div className="form-header">
        <div>
          <h1>Add New Vehicle</h1>
          <p>Enter the vehicle and driver information below</p>
        </div>
        <div className="header-buttons">
          <button type="button" className="cancel-btn">
            <X size={20} />
            Cancel
          </button>
          <button type="button" className="save-btn" onClick={handleSubmit}>
            <Save size={20} />
            Save
          </button>
        </div>
      </div>

      <form className="vehicle-form">
        <div className="form-grid">
          {/* Left Column */}
          <div className="form-left">
            {/* Vehicle Images */}
            <div className="form-section">
              <h2>Vehicle Images</h2>
              <div className="images-grid">
                {formData.images.map((image, index) => (
                  <div key={index} className="image-item">
                    <img src={image.preview} alt={`Vehicle ${index + 1}`} />
                    <button
                      type="button"
                      className="remove-image"
                      onClick={() => removeImage(index)}
                    >
                      <Trash2 size={16} />
                    </button>
                  </div>
                ))}
                <label className="image-upload">
                  <input
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={handleImageUpload}
                    hidden
                  />
                  <div className="upload-placeholder">
                    <Plus size={24} />
                    <span>Add Image</span>
                  </div>
                </label>
              </div>
            </div>

            {/* Basic Information */}
            <div className="form-section">
              <h2>Vehicle Information</h2>

              <div className="form-group">
                <label htmlFor="name">Vehicle Name*</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  className={errors.name ? 'error' : ''}
                />
                {errors.name && <span className="error-message">{errors.name}</span>}
              </div>

              <div className="form-group">
                <label htmlFor="type">Vehicle Type*</label>
                <select
                  id="type"
                  name="type"
                  value={formData.type}
                  onChange={handleInputChange}
                  className={errors.type ? 'error' : ''}
                >
                  <option value="">Select vehicle type</option>
                  {vehicleTypes.map(type => (
                    <option key={type} value={type}>{type}</option>
                  ))}
                </select>
                {errors.type && <span className="error-message">{errors.type}</span>}
              </div>

              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="plateNumber">Plate Number*</label>
                  <input
                    type="text"
                    id="plateNumber"
                    name="plateNumber"
                    value={formData.plateNumber}
                    onChange={handleInputChange}
                    className={errors.plateNumber ? 'error' : ''}
                  />
                  {errors.plateNumber && <span className="error-message">{errors.plateNumber}</span>}
                </div>

                <div className="form-group">
                  <label htmlFor="capacity">Capacity*</label>
                  <input
                    type="number"
                    id="capacity"
                    name="capacity"
                    value={formData.capacity}
                    onChange={handleInputChange}
                    className={errors.capacity ? 'error' : ''}
                  />
                  {errors.capacity && <span className="error-message">{errors.capacity}</span>}
                </div>
              </div>

              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="mileage">Mileage (km)</label>
                  <input
                    type="number"
                    id="mileage"
                    name="mileage"
                    value={formData.mileage}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="fuelType">Fuel Type</label>
                  <select
                    id="fuelType"
                    name="fuelType"
                    value={formData.fuelType}
                    onChange={handleInputChange}
                  >
                    <option value="">Select fuel type</option>
                    {fuelTypes.map(type => (
                      <option key={type} value={type}>{type}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            {/* Features */}
            <div className="form-section">
              <h2>Features</h2>
              <div className="features-grid">
                {commonFeatures.map(feature => (
                  <label key={feature} className="feature-checkbox">
                    <input
                      type="checkbox"
                      checked={formData.features.includes(feature)}
                      onChange={() => handleFeatureToggle(feature)}
                    />
                    <span>{feature}</span>
                  </label>
                ))}
              </div>
            </div>
          </div>

          {/* Right Column */}
          <div className="form-right">
            {/* Driver Information */}
            <div className="form-section">
              <h2>Driver Information</h2>

              <div className="form-group">
                <label htmlFor="driverName">Driver Name*</label>
                <input
                  type="text"
                  id="driverName"
                  name="name"
                  value={formData.driver.name}
                  onChange={handleDriverChange}
                  className={errors.driverName ? 'error' : ''}
                />
                {errors.driverName && <span className="error-message">{errors.driverName}</span>}
              </div>

              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="driverPhone">Phone Number*</label>
                  <input
                    type="tel"
                    id="driverPhone"
                    name="phone"
                    value={formData.driver.phone}
                    onChange={handleDriverChange}
                    className={errors.driverPhone ? 'error' : ''}
                  />
                  {errors.driverPhone && <span className="error-message">{errors.driverPhone}</span>}
                </div>

                <div className="form-group">
                  <label htmlFor="driverLicense">License Number*</label>
                  <input
                    type="text"
                    id="driverLicense"
                    name="license"
                    value={formData.driver.license}
                    onChange={handleDriverChange}
                    className={errors.driverLicense ? 'error' : ''}
                  />
                  {errors.driverLicense && <span className="error-message">{errors.driverLicense}</span>}
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="driverExperience">Years of Experience</label>
                <input
                  type="number"
                  id="driverExperience"
                  name="experience"
                  value={formData.driver.experience}
                  onChange={handleDriverChange}
                />
              </div>

              <div className="form-group">
                <label htmlFor="driverAddress">Address</label>
                <textarea
                  id="driverAddress"
                  name="address"
                  value={formData.driver.address}
                  onChange={handleDriverChange}
                  rows="3"
                />
              </div>

              <div className="form-group">
                <label htmlFor="emergencyContact">Emergency Contact</label>
                <input
                  type="text"
                  id="emergencyContact"
                  name="emergencyContact"
                  value={formData.driver.emergencyContact}
                  onChange={handleDriverChange}
                />
              </div>
            </div>

            {/* Maintenance Information */}
            <div className="form-section">
              <h2>Maintenance Information</h2>

              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="lastMaintenance">Last Maintenance</label>
                  <input
                    type="date"
                    id="lastMaintenance"
                    name="lastMaintenance"
                    value={formData.maintenanceInfo.lastMaintenance}
                    onChange={handleMaintenanceChange}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="nextMaintenance">Next Maintenance</label>
                  <input
                    type="date"
                    id="nextMaintenance"
                    name="nextMaintenance"
                    value={formData.maintenanceInfo.nextMaintenance}
                    onChange={handleMaintenanceChange}
                  />
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="maintenanceNotes">Maintenance Notes</label>
                <textarea
                  id="maintenanceNotes"
                  name="notes"
                  value={formData.maintenanceInfo.notes}
                  onChange={handleMaintenanceChange}
                  rows="3"
                  placeholder="Enter any maintenance notes or requirements..."
                />
              </div>
            </div>

            {/* Status */}
            <div className="form-section">
              <h2>Status</h2>
              <div className="status-options">
                {['available', 'maintenance', 'on_trip', 'inactive'].map(status => (
                  <label key={status} className="status-radio">
                    <input
                      type="radio"
                      name="status"
                      value={status}
                      checked={formData.status === status}
                      onChange={handleInputChange}
                    />
                    <span>{status.replace('_', ' ').charAt(0).toUpperCase() + status.slice(1)}</span>
                  </label>
                ))}
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default TransportationForm;