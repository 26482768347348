import React, { useState } from 'react';
import { 
  Search, 
  Plus, 
  MapPin, 
  Calendar,
  Users,
  Clock,
  Star,
  MoreVertical,
  ChevronRight,
  Car,
  Coffee,
  Tent,
  DollarSign,
  Shield,
  CheckCircle,
  Info
} from 'lucide-react';
import './Index.scss';

const TourPackageList = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filterDestination, setFilterDestination] = useState('all');
  const [filterDuration, setFilterDuration] = useState('all');
  const [viewMode, setViewMode] = useState('grid');
  const [selectedPackage, setSelectedPackage] = useState(null);

  // Format price in IDR
  const formatPrice = (price) => {
    return new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(price);
  };

  // Get min price from pricing array
  const getMinPrice = (pricing) => {
    return Math.min(...pricing.map(p => p.price));
  };

  const tourPackages = [
    {
      id: 1,
      name: "Tumpak Sewu & Bromo Exploration",
      subTitle: "3 Days 2 Nights from Bali",
      description: "Experience an exciting 3-day tour from Bali, including a visit to the magnificent Tumpak Sewu Waterfall, the stunning sunrise at Mount Bromo, and a return to Surabaya.",
      thumbnail: "https://lh4.googleusercontent.com/proxy/oGbxw20kvZ9LH_bpEP-7SlMUQ-ToXEUax478Ygev8cfzmzeSWqRhgGhFG7WB-voHQ8gJVvWjdp7BfRJxsfzlXwF5UgS-1PkX9WBFfnk9X9BdMuX8EZDsb8Q",
      duration: {
        days: 3,
        nights: 2
      },
      destinations: ['Bali', 'Bondowoso', 'Tumpak Sewu', 'Mount Bromo', 'Surabaya'],
      pricing: [
        { pax: '11+', price: 2600000 },
        { pax: '8-10', price: 2750000 },
        { pax: '5-7', price: 2950000 },
        { pax: '3-4', price: 3100000 },
        { pax: '2', price: 3550000 }
      ],
      included: [
        {
          category: 'Transport',
          items: [
            'Private Transport (Avanza for 1-2 pax, Hiace for 4 pax above)',
            'Private 4WD Jeep at Bromo area for 1-4 Pax'
          ]
        },
        {
          category: 'Accommodation',
          items: [
            'Hotel Room (3-4⭐)',
            'Room Type: Twin/Double/Triple/Quad Bed Room'
          ]
        },
        {
          category: 'Staff',
          items: [
            'Licensed and certified tour guides'
          ]
        }
      ],
      highlights: [
        'Tumpak Sewu Waterfall',
        'Mount Bromo Sunrise',
        'Sea of Sand',
        'Tengger Culture'
      ],
      status: 'available'
    },
    {
      id: 2,
      name: "Ijen Blue Fire & Bromo Sunrise",
      subTitle: "2 Days 1 Night Adventure",
      description: "Witness the magical blue flames of Ijen Crater and experience the breathtaking sunrise at Mount Bromo in this compact adventure tour.",
      thumbnail: "https://nusantara-news.co/wp-content/uploads/2023/12/WhatsApp-Image-2023-06-28-at-18.29.33.jpeg",
      duration: {
        days: 2,
        nights: 1
      },
      destinations: ['Banyuwangi', 'Ijen Crater', 'Mount Bromo'],
      pricing: [
        { pax: '11+', price: 2100000 },
        { pax: '8-10', price: 2300000 },
        { pax: '5-7', price: 2500000 },
        { pax: '3-4', price: 2800000 },
        { pax: '2', price: 3200000 }
      ],
      included: [
        {
          category: 'Transport',
          items: ['Private Transport', 'Private 4WD Jeep']
        },
        {
          category: 'Accommodation',
          items: ['Hotel Room (3⭐)']
        },
        {
          category: 'Equipment',
          items: ['Gas Mask', 'Safety Gear']
        }
      ],
      highlights: [
        'Blue Fire Phenomenon',
        'Ijen Crater Lake',
        'Mount Bromo Sunrise',
        'Traditional Village'
      ],
      status: 'popular'
    },
    {
      id: 3,
      name: "East Java Heritage Tour",
      subTitle: "4 Days 3 Nights Cultural Experience",
      description: "Explore the rich cultural heritage of East Java, from ancient temples to traditional villages and local crafts.",
      thumbnail: "https://www.masuksini.com/image/large/mengenal%20sejarah%20kota%20malang.jpg",
      duration: {
        days: 4,
        nights: 3
      },
      destinations: ['Surabaya', 'Malang', 'Trowulan', 'Mount Bromo'],
      pricing: [
        { pax: '11+', price: 3200000 },
        { pax: '8-10', price: 3400000 },
        { pax: '5-7', price: 3700000 },
        { pax: '3-4', price: 4000000 },
        { pax: '2', price: 4500000 }
      ],
      included: [
        {
          category: 'Transport',
          items: ['Private Transport', 'Airport Transfer']
        },
        {
          category: 'Accommodation',
          items: ['Hotel Room (4⭐)']
        },
        {
          category: 'Guide',
          items: ['Cultural Expert Guide']
        }
      ],
      highlights: [
        'Ancient Temples',
        'Traditional Markets',
        'Local Crafts',
        'Cultural Shows'
      ],
      status: 'available'
    },
    {
      id: 4,
      name: "Malang City Explorer",
      subTitle: "1 Day City Tour",
      description: "Discover the charm of Malang city with its colonial architecture, vibrant markets, and culinary delights.",
      thumbnail: "https://cdn1-production-images-kly.akamaized.net/fY6hgoY-3XOnRV-V64M89qbbYNE=/800x450/smart/filters:quality(75):strip_icc():format(webp)/kly-media-production/medias/4212007/original/082546200_1667373575-q2.jpg",
      duration: {
        days: 1,
        nights: 0
      },
      destinations: ['Malang'],
      pricing: [
        { pax: '11+', price: 500000 },
        { pax: '8-10', price: 600000 },
        { pax: '5-7', price: 700000 },
        { pax: '3-4', price: 800000 },
        { pax: '2', price: 1000000 }
      ],
      included: [
        {
          category: 'Transport',
          items: ['Private Car']
        },
        {
          category: 'Guide',
          items: ['Local Guide']
        },
        {
          category: 'Meals',
          items: ['Lunch']
        }
      ],
      highlights: [
        'Colonial Buildings',
        'Local Markets',
        'Food Tour',
        'City Parks'
      ],
      status: 'available'
    },
    {
      id: 5,
      name: "Bromo Madakaripura Special",
      subTitle: "2 Days 1 Night Adventure",
      description: "Combine the majestic Mount Bromo sunrise with a visit to the spectacular Madakaripura Waterfall.",
      thumbnail: "https://kawanjelajahtour.com/wp-content/uploads/2023/03/Blue-Fire-Kawah-Ijen-e1677679648678.jpg",
      duration: {
        days: 2,
        nights: 1
      },
      destinations: ['Surabaya', 'Mount Bromo', 'Madakaripura'],
      pricing: [
        { pax: '11+', price: 1900000 },
        { pax: '8-10', price: 2100000 },
        { pax: '5-7', price: 2300000 },
        { pax: '3-4', price: 2600000 },
        { pax: '2', price: 3000000 }
      ],
      included: [
        {
          category: 'Transport',
          items: ['Private Transport', '4WD Jeep']
        },
        {
          category: 'Accommodation',
          items: ['Hotel Room (3⭐)']
        },
        {
          category: 'Equipment',
          items: ['Raincoat', 'Trekking Gear']
        }
      ],
      highlights: [
        'Mount Bromo Sunrise',
        'Madakaripura Waterfall',
        'Sea of Sand',
        'Local Village'
      ],
      status: 'limited'
    },
    {
      id: 6,
      name: "Complete East Java Explorer",
      subTitle: "5 Days 4 Nights Full Experience",
      description: "The ultimate East Java tour covering Bromo, Ijen, Tumpak Sewu, and cultural highlights.",
      thumbnail: "https://assets.promediateknologi.id/crop/0x0:0x0/750x500/webp/photo/2023/06/26/rixrvqnu94le2rdwk1vb-1479414290.jpg",
      duration: {
        days: 5,
        nights: 4
      },
      destinations: ['Surabaya', 'Mount Bromo', 'Ijen', 'Tumpak Sewu', 'Malang'],
      pricing: [
        { pax: '11+', price: 4500000 },
        { pax: '8-10', price: 4800000 },
        { pax: '5-7', price: 5200000 },
        { pax: '3-4', price: 5600000 },
        { pax: '2', price: 6000000 }
      ],
      included: [
        {
          category: 'Transport',
          items: ['Private Transport', '4WD Jeep']
        },
        {
          category: 'Accommodation',
          items: ['Hotel Room (4⭐)']
        },
        {
          category: 'Guide',
          items: ['Professional Guide']
        },
        {
          category: 'Equipment',
          items: ['Complete Gear Set']
        }
      ],
      highlights: [
        'All Major Destinations',
        'Premium Experience',
        'Full Guide Support',
        'Best Photo Spots'
      ],
      status: 'popular'
    }
  ];

  return (
    <div className="tour-package-list">
      {/* Header */}
      <div className="list-header">
        <div className="header-left">
          <h1>Tour Packages</h1>
          <p>Explore our curated selection of adventure tours</p>
        </div>
        <div className="header-actions">
          <button className="add-btn">
            <Plus size={20} />
            New Package
          </button>
        </div>
      </div>

      {/* Filters */}
      <div className="list-filters">
        <div className="filters-left">
          <div className="search-bar">
            <Search size={20} />
            <input
              type="text"
              placeholder="Search tour packages..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className="filter-group">
            <select
              value={filterDuration}
              onChange={(e) => setFilterDuration(e.target.value)}
              className="filter-select"
            >
              <option value="all">All Durations</option>
              <option value="1">Day Trip</option>
              <option value="2">2D1N</option>
              <option value="3">3D2N</option>
              <option value="4">4D3N+</option>
            </select>
          </div>
        </div>
        
        <div className="filters-right">
          <div className="view-switcher">
            <button
              className={`view-btn ${viewMode === 'grid' ? 'active' : ''}`}
              onClick={() => setViewMode('grid')}
            >
              Grid
            </button>
            <button
              className={`view-btn ${viewMode === 'table' ? 'active' : ''}`}
              onClick={() => setViewMode('table')}
            >
              Table
            </button>
          </div>
        </div>
      </div>

      {/* Grid View */}
      {viewMode === 'grid' && (
        <div className="package-grid">
          {tourPackages.map(pkg => (
            <div key={pkg.id} className="package-card">
              {/* Card Image */}
              <div className="card-image">
                <img src={pkg.thumbnail} alt={pkg.name} />
                <div className="image-overlay">
                  <div className="duration-badge">
                    <Clock size={16} />
                    <span>{pkg.duration.days}D{pkg.duration.nights}N</span>
                  </div>
                  <button className="more-btn">
                    <MoreVertical size={16} />
                  </button>
                </div>
              </div>

              {/* Card Content */}
              <div className="card-content">
                <div className="package-info">
                  <h3>{pkg.name}</h3>
                  <p className="subtitle">{pkg.subTitle}</p>
                </div>

                {/* Destinations */}
                <div className="destinations">
                  {pkg.destinations.map((dest, index) => (
                    <React.Fragment key={index}>
                      <span>{dest}</span>
                      {index < pkg.destinations.length - 1 && (
                        <ChevronRight size={16} className="chevron" />
                      )}
                    </React.Fragment>
                  ))}
                </div>

                {/* Quick Info */}
                <div className="quick-info">
                  <div className="info-item">
                    <Users size={16} />
                    <span>Min. 2 Persons</span>
                  </div>
                  <div className="info-item">
                    <Car size={16} />
                    <span>Private Transport</span>
                  </div>
                  <div className="info-item">
                    <Coffee size={16} />
                    <span>Meals Included</span>
                  </div>
                </div>

                {/* Highlights */}
                <div className="highlights">
                  <h4>Highlights</h4>
                  <div className="highlight-tags">
                    {pkg.highlights.slice(0, 4).map((highlight, index) => (
                      <span key={index} className="highlight-tag">
                        {highlight}
                      </span>
                    ))}
                  </div>
                </div>

                {/* Inclusions */}
                <div className="inclusions">
                  <h4>What's Included</h4>
                  <div className="inclusion-grid">
                    {pkg.included.slice(0, 6).map((inc, index) => (
                      <div key={index} className="inclusion-item">
                        <CheckCircle size={14} />
                        <span>{inc.category}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {/* Card Footer */}
              <div className="card-footer">
                <div className="price-info">
                  <div className="price-range">
                    <span className="label">Starting from</span>
                    <span className="price">{formatPrice(getMinPrice(pkg.pricing))}</span>
                  </div>
                  <span className="price-note">per person</span>
                </div>
                <div className="action-buttons">
                  <button className="action-btn">View Details</button>
                  <button className="action-btn primary">Book Now</button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Table View */}
      {viewMode === 'table' && (
        <div className="package-table">
          <table>
            <thead>
              <tr>
                <th>Package Name</th>
                <th>Duration</th>
                <th>Start From</th>
                <th>Destinations</th>
                <th>Price (per pax)</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {tourPackages.map(pkg => (
                <tr key={pkg.id}>
                  <td>
                    <div className="package-cell">
                      <img src={pkg.thumbnail} alt={pkg.name} />
                      <div>
                        <span className="name">{pkg.name}</span>
                        <span className="subtitle">{pkg.subTitle}</span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="duration-cell">
                      <Clock size={16} />
                      <span>{pkg.duration.days}D{pkg.duration.nights}N</span>
                    </div>
                  </td>
                  <td>{pkg.destinations[0]}</td>
                  <td>
                    <div className="destinations-cell">
                      {pkg.destinations.map((dest, index) => (
                        <span key={index} className="destination-tag">
                          {dest}
                        </span>
                      ))}
                    </div>
                  </td>
                  <td>
                    <div className="price-cell">
                      <div className="price-ranges">
                        {pkg.pricing.map((price, index) => (
                          <div key={index} className="price-range-item">
                            <span className="pax">{price.pax}</span>
                            <span className="price">{formatPrice(price.price)}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="action-buttons">
                      <button className="icon-btn">
                        <Info size={16} />
                      </button>
                      <button className="icon-btn edit">
                        <Plus size={16} />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default TourPackageList;
