import React from 'react';
import { Menu, Bell } from 'lucide-react';
import './Navbar.scss';

const Navbar = ({ toggleSidebar }) => {
  return (
    <nav className="navbar">
      <div className="navbar-left">
        <button onClick={toggleSidebar} className="nav-icon-button">
          <Menu size={20} />
        </button>
        <div className="logo-container">
          <h1 className="logo-text">JVTO</h1>
          <span className="logo-subtext">Tour Management</span>
        </div>
      </div>

      <div className="navbar-right">
        <button className="nav-icon-button notification-button">
          <Bell size={20} />
          <span className="notification-badge">3</span>
        </button>
        <div className="user-profile">
          <img
            src="https://static-00.iconduck.com/assets.00/avatar-default-icon-2048x2048-h6w375ur.png"
            alt="User"
            className="user-avatar"
          />
          <div className="user-info">
            <span className="user-name">John Doe</span>
            <span className="user-role">Administrator</span>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;