import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { 
  Home, 
  CalendarCheck, 
  Users, 
  Route, 
  CalendarRange, 
  FileText, 
  PieChart, 
  Settings,
  ChevronDown,
  CreditCard,
  Building2,
  UserCog
} from 'lucide-react';
import './Sidebar.scss';

const Sidebar = ({ isOpen }) => {
  const [activeSubmenu, setActiveSubmenu] = useState('');

  const toggleSubmenu = (menuKey) => {
    setActiveSubmenu(activeSubmenu === menuKey ? '' : menuKey);
  };

  const menuItems = [
    {
      icon: <Home size={20} />,
      label: 'Dashboard',
      path: '/',
      defaultActive: true
    },
    {
      icon: <CalendarCheck size={20} />,
      label: 'Booking Management',
      key: 'booking',
      submenu: [
        { label: 'All Bookings', path: '/bookings' },
        { label: 'New Booking', path: '/bookings/new' },
        { label: 'TWT Bookings', path: '/bookings/twt' },
        { label: 'Klook Bookings', path: '/bookings/klook' },
        { label: 'Calendar View', path: '/bookings/calendar' }
      ]
    },
    {
      icon: <Users size={20} />,
      label: 'Client Management',
      key: 'clients',
      submenu: [
        { label: 'Client List', path: '/clients' },
        { label: 'New Client', path: '/clients/new' },
        { label: 'Contact History', path: '/clients/history' },
        { label: 'Client Preferences', path: '/clients/preferences' }
      ]
    },
    {
      icon: <Route size={20} />,
      label: 'Itinerary & Activities',
      key: 'itinerary',
      submenu: [
        { label: 'Itineraries', path: '/itineraries' },
        { label: 'Activities', path: '/itineraries/activities' },
        { label: 'Locations', path: '/itineraries/locations' },
        { label: 'Templates', path: '/itineraries/templates' }
      ]
    },
    {
      icon: <CalendarRange size={20} />,
      label: 'Resource Management',
      key: 'resources',
      submenu: [
        { label: 'Resource Dashboard', path: '/resources' },
        { label: 'Vehicles', path: '/resources/vehicles' },
        { label: 'Crews', path: '/resources/crews' },
        { label: 'Hotels', path: '/resources/hotels' },
        { label: 'Crew Schedule', path: '/resources/schedule' }
      ]
    },
    {
      icon: <FileText size={20} />,
      label: 'Financial Management',
      key: 'finance',
      submenu: [
        { label: 'Invoices', path: '/finance/invoices' },
        { label: 'Payments', path: '/finance/payments' },
        { label: 'Expenses', path: '/finance/expenses' },
        { label: 'Payment Gateway', path: '/finance/gateway' },
        { label: 'Financial Reports', path: '/finance/reports' }
      ]
    },
    {
      icon: <PieChart size={20} />,
      label: 'Analytics & Reports',
      key: 'analytics',
      submenu: [
        { label: 'Overview Dashboard', path: '/analytics' },
        { label: 'Booking Analytics', path: '/analytics/bookings' },
        { label: 'Resource Utilization', path: '/analytics/resources' },
        { label: 'Financial Analytics', path: '/analytics/finance' },
        { label: 'Custom Reports', path: '/analytics/custom' }
      ]
    },
    // {
    //   icon: <Building2 size={20} />,
    //   label: 'Company',
    //   key: 'company',
    //   submenu: [
    //     { label: 'Company Profile', path: '/company/profile' },
    //     { label: 'Departments', path: '/company/departments' },
    //     { label: 'Branches', path: '/company/branches' }
    //   ]
    // },
    {
      icon: <UserCog size={20} />,
      label: 'User Management',
      key: 'users',
      submenu: [
        { label: 'Users', path: '/users' },
        { label: 'Roles & Permissions', path: '/users/roles' },
        { label: 'Audit Logs', path: '/users/audit' }
      ]
    },
    // {
    //   icon: <Settings size={20} />,
    //   label: 'Settings',
    //   key: 'settings',
    //   submenu: [
    //     { label: 'General Settings', path: '/settings' },
    //     { label: 'Integration Settings', path: '/settings/integrations' },
    //     { label: 'Email Templates', path: '/settings/email-templates' },
    //     { label: 'Backup & Restore', path: '/settings/backup' }
    //   ]
    // }
  ];

  // Rest of the component code remains the same...
  const SubMenu = ({ item }) => {
    const isExpanded = activeSubmenu === item.key;
    
    return (
      <div className="menu-item">
        <button
          onClick={() => toggleSubmenu(item.key)}
          className={`menu-button ${isExpanded ? 'active' : ''}`}
        >
          <div className="menu-button-content">
            {item.icon}
            <span>{item.label}</span>
          </div>
          <ChevronDown className={`menu-chevron ${isExpanded ? 'rotate' : ''}`} size={16} />
        </button>
        
        <div className={`submenu ${isExpanded ? 'expanded' : ''}`}>
          {item.submenu.map((subItem, index) => (
            <NavLink
              key={index}
              to={subItem.path}
              className={({ isActive }) => `submenu-link ${isActive ? 'active' : ''}`}
            >
              <span>{subItem.label}</span>
            </NavLink>
          ))}
        </div>
      </div>
    );
  };

  const MenuLink = ({ item }) => {
    if (item.submenu) {
      return <SubMenu item={item} />;
    }

    return (
      <NavLink
        to={item.path}
        className={({ isActive }) => `menu-link ${isActive || item.defaultActive ? 'active' : ''}`}
        onClick={() => {
          setActiveSubmenu('');
        }}
      >
        {item.icon}
        <span>{item.label}</span>
      </NavLink>
    );
  };

  return (
    <aside className={`sidebar ${isOpen ? 'open' : ''}`}>
      <div className="sidebar-content">
        {menuItems.map((item, index) => (
          <MenuLink key={index} item={item} />
        ))}
      </div>
    </aside>
  );
};

export default Sidebar;