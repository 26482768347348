import React, { useState, useRef, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Search, Filter, Plus, MoreVertical, Phone, Mail, MapPin, Calendar, Star, Edit2 } from 'lucide-react';
import './Index.scss';

const CrewList = () => {
  const [filterStatus, setFilterStatus] = useState('all');
  const [viewMode, setViewMode] = useState('grid');
  const [searchTerm, setSearchTerm] = useState('');
  const [openDropdownId, setOpenDropdownId] = useState(null);

  // Reference for clicking outside dropdown
  const dropdownRef = useRef(null);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenDropdownId(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const crews = [
    {
      id: 1,
      name: 'John Smith',
      avatar: 'https://static-00.iconduck.com/assets.00/avatar-default-icon-2048x2048-h6w375ur.png',
      role: 'Tour Guide',
      specialization: 'Mountain Trekking',
      rating: 4.8,
      totalTours: 156,
      languages: ['English', 'Indonesian', 'Japanese'],
      phone: '+62 812-3456-7890',
      email: 'john.smith@example.com',
      location: 'Malang, East Java',
      status: 'available',
      nextTour: '2024-10-30',
      certifications: ['Mountain Guide Cert.', 'First Aid Cert.']
    },
    {
      id: 2,
      name: 'Sarah Johnson',
      avatar: 'https://static-00.iconduck.com/assets.00/avatar-default-icon-2048x2048-h6w375ur.png',
      role: 'Senior Guide',
      specialization: 'Cultural Tours',
      rating: 4.9,
      totalTours: 203,
      languages: ['English', 'Indonesian', 'Dutch'],
      phone: '+62 813-5678-9012',
      email: 'sarah.j@example.com',
      location: 'Yogyakarta',
      status: 'onTour',
      nextTour: '2024-10-28',
      certifications: ['Cultural Heritage Guide', 'Tourism Management']
    },
    {
      id: 3,
      name: 'Budi Santoso',
      avatar: 'https://static-00.iconduck.com/assets.00/avatar-default-icon-2048x2048-h6w375ur.png',
      role: 'Local Guide',
      specialization: 'Volcano Tours',
      rating: 4.7,
      totalTours: 142,
      languages: ['Indonesian', 'English'],
      phone: '+62 814-7890-1234',
      email: 'budi.s@example.com',
      location: 'Probolinggo',
      status: 'onLeave',
      nextTour: '2024-11-05',
      certifications: ['Volcano Safety Cert.', 'Emergency Response']
    },
    {
      id: 4,
      name: 'Maria Chen',
      avatar: 'https://static-00.iconduck.com/assets.00/avatar-default-icon-2048x2048-h6w375ur.png',
      role: 'Tour Guide',
      specialization: 'City Tours',
      rating: 4.6,
      totalTours: 98,
      languages: ['English', 'Mandarin', 'Indonesian'],
      phone: '+62 815-2345-6789',
      email: 'maria.c@example.com',
      location: 'Surabaya',
      status: 'available',
      nextTour: '2024-10-29',
      certifications: ['City Guide Cert.', 'First Aid']
    }
  ];

  const statusColors = {
    available: { bg: 'bg-green-100', text: 'text-green-800' },
    onTour: { bg: 'bg-blue-100', text: 'text-blue-800' },
    onLeave: { bg: 'bg-orange-100', text: 'text-orange-800' }
  };

  // Filter and search handlers
  const filteredCrews = crews.filter(crew => {
    const matchesSearch = crew.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                         crew.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
                         crew.location.toLowerCase().includes(searchTerm.toLowerCase());
    
    const matchesStatus = filterStatus === 'all' || crew.status === filterStatus;
    
    return matchesSearch && matchesStatus;
  });

  const handleEditCrew = (crewId) => {
    console.log('Edit crew:', crewId);
    setOpenDropdownId(null);
  };

  const DropdownMenu = ({ crewId }) => (
    <div className="dropdown-menu" ref={dropdownRef}>
      <button onClick={() => handleEditCrew(crewId)} className="dropdown-item">
        <Edit2 size={16} />
        <span>Edit</span>
      </button>
    </div>
  );

  return (
    <div className="crew-list">
      {/* Header Section */}
      <div className="crew-header">
        <div className="header-left">
          <h1>Crew Management</h1>
          <p>Manage your tour guides and staff</p>
        </div>
        <NavLink to="/resources/crews/create" className="add-crew-btn">
          <Plus size={20} />
          Add New Crew
        </NavLink>
      </div>

      {/* Filters and Search */}
      <div className="crew-filters">
        <div className="search-bar">
          <Search size={20} />
          <input 
            type="text" 
            placeholder="Search crew members..." 
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <div className="filter-group">
          <button 
            className={`filter-btn ${filterStatus === 'all' ? 'active' : ''}`}
            onClick={() => setFilterStatus('all')}
          >
            All
          </button>
          <button 
            className={`filter-btn ${filterStatus === 'available' ? 'active' : ''}`}
            onClick={() => setFilterStatus('available')}
          >
            Available
          </button>
          <button 
            className={`filter-btn ${filterStatus === 'onTour' ? 'active' : ''}`}
            onClick={() => setFilterStatus('onTour')}
          >
            On Tour
          </button>
          <button 
            className={`filter-btn ${filterStatus === 'onLeave' ? 'active' : ''}`}
            onClick={() => setFilterStatus('onLeave')}
          >
            On Leave
          </button>
        </div>

        <div className="view-toggle">
          <button 
            className={`toggle-btn ${viewMode === 'grid' ? 'active' : ''}`}
            onClick={() => setViewMode('grid')}
          >
            Grid
          </button>
          <button 
            className={`toggle-btn ${viewMode === 'table' ? 'active' : ''}`}
            onClick={() => setViewMode('table')}
          >
            Table
          </button>
        </div>
      </div>

      {/* Grid View */}
      {viewMode === 'grid' && (
        <div className="crew-grid">
          {filteredCrews.length > 0 ? (
            filteredCrews.map(crew => (
              <div key={crew.id} className="crew-card">
                <div className="card-header">
                  <img src={crew.avatar} alt={crew.name} className="crew-avatar" />
                  <div className="dropdown-container" ref={dropdownRef}>
                    <button 
                      className="more-btn"
                      onClick={() => setOpenDropdownId(openDropdownId === crew.id ? null : crew.id)}
                    >
                      <MoreVertical size={16} />
                    </button>
                    {openDropdownId === crew.id && <DropdownMenu crewId={crew.id} />}
                  </div>
                </div>

                <div className="card-body">
                  <div className="crew-info">
                    <h3>{crew.name}</h3>
                    <span className="role">{crew.role}</span>
                    <span className={`status ${statusColors[crew.status].bg} ${statusColors[crew.status].text}`}>
                      {crew.status.replace(/([A-Z])/g, ' $1').trim()}
                    </span>
                  </div>

                  <div className="crew-stats">
                    <div className="stat">
                      <Star size={16} className="text-yellow-500" />
                      <span>{crew.rating}</span>
                    </div>
                    <div className="stat">
                      <Calendar size={16} />
                      <span>{crew.totalTours} tours</span>
                    </div>
                  </div>

                  <div className="crew-details">
                    <div className="detail-item">
                      <Phone size={16} />
                      <span>{crew.phone}</span>
                    </div>
                    <div className="detail-item">
                      <Mail size={16} />
                      <span>{crew.email}</span>
                    </div>
                    <div className="detail-item">
                      <MapPin size={16} />
                      <span>{crew.location}</span>
                    </div>
                  </div>

                  <div className="crew-languages">
                    <span className="detail-label">Languages:</span>
                    <div className="language-tags">
                      {crew.languages.map((lang, index) => (
                        <span key={index} className="language-tag">{lang}</span>
                      ))}
                    </div>
                  </div>

                  <div className="crew-certifications">
                    <span className="detail-label">Certifications:</span>
                    <div className="cert-tags">
                      {crew.certifications.map((cert, index) => (
                        <span key={index} className="cert-tag">{cert}</span>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="card-footer">
                  <button className="view-profile-btn">View Profile</button>
                  <button className="schedule-btn">Schedule</button>
                </div>
              </div>
            ))
          ) : (
            <div className="no-results">
              <p>No crew members found matching your search criteria.</p>
            </div>
          )}
        </div>
      )}

{/* Table View */}
{viewMode === 'table' && (
  <div className="crew-table">
    {filteredCrews.length > 0 ? (
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Role</th>
            <th>Status</th>
            <th>Rating</th>
            <th>Languages</th>
            <th>Location</th>
            <th>Next Tour</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredCrews.map(crew => (
            <tr key={crew.id}>
              <td>
                <div className="crew-name-cell">
                  <img src={crew.avatar} alt={crew.name} />
                  <div>
                    <span className="name">{crew.name}</span>
                    <span className="email">{crew.email}</span>
                  </div>
                </div>
              </td>
              <td>{crew.role}</td>
              <td>
                <span className={`status-badge ${statusColors[crew.status].bg} ${statusColors[crew.status].text}`}>
                  {crew.status.replace(/([A-Z])/g, ' $1').trim()}
                </span>
              </td>
              <td>
                <div className="rating">
                  <Star size={16} className="text-yellow-500" />
                  <span>{crew.rating}</span>
                </div>
              </td>
              <td>
                <div className="languages">
                  {crew.languages.map((lang, index) => (
                    <span key={index} className="language-tag">
                      {lang}
                    </span>
                  ))}
                </div>
              </td>
              <td>{crew.location}</td>
              <td>{crew.nextTour}</td>
              <td>
                <div className="action-buttons">
                  <button 
                    className="action-btn view-btn"
                  >
                    View
                  </button>
                  <button 
                    className="action-btn edit-btn"
                    onClick={() => handleEditCrew(crew.id)}
                  >
                    Edit
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    ) : (
      <div className="no-results">
        <p>No crew members found matching your search criteria.</p>
      </div>
    )}
  </div>
)}
    </div>
  );
};

export default CrewList;